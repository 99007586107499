import { Confirm } from "notiflix";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate } from "react-router-dom";
import { useEditSpecialInstructions, useReplacementStudyDelete, useReplacementSummary } from "../../../apis/studyApi";
import * as S from "../../../elements/Button/style/ButtonLayout.style";
import * as S3 from "../../../elements/Form/style/Form.style";
import * as S4 from "../../../styles/Common";
import { IK } from "../../../utils/i18n/keyStore";
import { CardTail } from "../../layout/CardLayout/CardLayout";
import * as S2 from "../style/Study.style";
import { SummaryTableLi } from "./StudyViewSummary";

const StudyViewReplaceSummary = ({ handlePrint, replacementId, patientId, studyAuth }) => {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isLoading, isError } = useReplacementSummary(replacementId, patientId);
  const replacementInfo = data?.data;

  const replacementStudyDeleteMutation = useReplacementStudyDelete();
  /**처방전 삭제 */
  const deleteStudy = () => {
    Confirm.show(
      t(IK.warning_delete_1),
      t(IK.warning_delete_2),
      t(IK.confirm),
      t(IK.cancel),
      () => {
        replacementStudyDeleteMutation.mutate(replacementId);
      },
      () => {}
    );
  };

  /**특별지시사항 수정 */
  const [isEditSpecialInstruction, setIsEditSpecialInstruction] = useState(false);
  const [specialInstruction, setSpecialInstruction] = useState(replacementInfo?.SPECIAL_INSTRUCTIONS);

  useEffect(() => {
    if (!isLoading && replacementInfo) {
      setSpecialInstruction(replacementInfo?.SPECIAL_INSTRUCTIONS);
    }
  }, [isLoading, replacementInfo]);

  const specialInstructionMutation = useEditSpecialInstructions("ReplacementSummary");

  /**특별지시사항 등록 */
  const editSpecialInstruction = () => {
    specialInstructionMutation.mutate(
      {
        type: "replacement",
        id: replacementId,
        data: { memo: specialInstruction },
      },
      {
        onSuccess: () => {
          setIsEditSpecialInstruction(false);
        },
      }
    );
  };

  return (
    <>
      {isLoading || isError ? (
        <Skeleton height={800} />
      ) : (
        <>
          <S2.StudySummary>
            {/* 의사명 */}
            <SummaryTableLi title={t(IK.doctor_name)}>
              {replacementInfo?.doctorName} {replacementInfo?.doctorKorName ? `(${replacementInfo.doctorKorName})` : ""}
            </SummaryTableLi>

            {/* 인쇄시 환자명, 치료옵션 보이도록 */}
            <SummaryTableLi printshow title={t(IK.patient_name)}>
              {replacementInfo?.patientName && <p>{`${replacementInfo.patientName} ${replacementInfo.patientKorName ? ` (${replacementInfo.patientKorName})` : ""}`}</p>}
            </SummaryTableLi>
            <SummaryTableLi printshow title={t(IK.treatment_option)}>
              {replacementInfo?.studyPackages && <p>{`${t(IK[replacementInfo.studyPackages])}`}</p>}
            </SummaryTableLi>

            {/* 배송지 */}
            <SummaryTableLi title={t(IK.shipping_address)}>
              <p>{replacementInfo?.patientShipAddress}</p>
            </SummaryTableLi>

            {/* 청구지 */}
            <SummaryTableLi title={t(IK.billing_address)}>
              <p>{replacementInfo?.patientBillAddress}</p>
            </SummaryTableLi>

            {/* 의사 연락처 */}
            <SummaryTableLi title={t(IK.doctor_tel)}>{replacementInfo?.doctorPhoneNumber && <p>{replacementInfo?.doctorPhoneNumber}</p>}</SummaryTableLi>

            {/* 주문할 대체 교정 장치의 단계 */}
            <SummaryTableLi title={t(IK.order_replace_device_step)}>
              <S3.FormControlsColumn>
                {replacementInfo?.UPPER && <p>{`${t(IK.maxilla)} ${replacementInfo.UPPER}`}</p>}
                {replacementInfo?.LOWER && <p>{`${t(IK.mandible)} ${replacementInfo.LOWER}`}</p>}
                {replacementInfo?.UPPER_ATTACH && <p>{`Attach template ${t(IK.maxilla)}  ${replacementInfo.UPPER_ATTACH}`}</p>}
                {replacementInfo?.LOWER_ATTACH && <p>{`Attach template ${t(IK.mandible)} ${replacementInfo.LOWER_ATTACH}`}</p>}
              </S3.FormControlsColumn>
            </SummaryTableLi>

            {/* 특별지시사항 */}
            <SummaryTableLi title={t(IK.special_instructions)} printhide>
              {isEditSpecialInstruction ? (
                <S3.FormControlsColumn>
                  <S3.FormControls as="textarea" $textarea $full defaultValue={replacementInfo.SPECIAL_INSTRUCTIONS} onChange={(e) => setSpecialInstruction(e.target.value)} />
                  <S.ButtonFlex $small>
                    <S.StyledSmallButton as="button" type="button" onClick={editSpecialInstruction}>
                      {t(IK.registration)}
                    </S.StyledSmallButton>
                    <S.StyledSmallButton $secondary as="button" type="button" onClick={() => setIsEditSpecialInstruction(false)}>
                      {t(IK.cancel)}
                    </S.StyledSmallButton>
                  </S.ButtonFlex>
                </S3.FormControlsColumn>
              ) : (
                <S3.FormControlsFlex>
                  {replacementInfo.SPECIAL_INSTRUCTIONS ? <S4.TextDiv $textpreline>{specialInstruction}</S4.TextDiv> : <></>}
                  {studyAuth?.userPermission?.studySpecialFix && (
                    <S.StyledSmallButton as="button" type="button" onClick={() => setIsEditSpecialInstruction(true)}>
                      {t(IK.modify)}
                    </S.StyledSmallButton>
                  )}
                </S3.FormControlsFlex>
              )}
            </SummaryTableLi>
          </S2.StudySummary>
          <CardTail line>
            <S.ButtonLtBox>
              <S.StyledButton as="button" type="button" onClick={() => navigate(`/studyList${location?.state?.herf || "?page=1"}`)}>
                {t(IK.list)}
              </S.StyledButton>
              <S.StyledButton as="button" type="button" $primary onClick={handlePrint}>
                {t(IK.print)}
              </S.StyledButton>
              {studyAuth?.userPermission?.studySuperAuth && (
                <S.StyledButton as="button" type="button" $primary onClick={deleteStudy}>
                  {t(IK.delete)}
                </S.StyledButton>
              )}
            </S.ButtonLtBox>
          </CardTail>
        </>
      )}
    </>
  );
};

export default StudyViewReplaceSummary;
