import Notiflix, { Notify } from "notiflix";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import ImageViewer from "react-simple-image-viewer";
import { downloadFile } from "../../apis/downloadApi";
import { useDeleteStudyImg, usePostStudyImg } from "../../apis/studyApi";
import * as S2 from "../../styles/Common";
import { IK } from "../../utils/i18n/keyStore";
import { rotateImage } from "../../utils/imageRotate";
import { TitleV3 } from "../Title/TitleText";
import * as S from "./style/Drop.style";

export const DragImageBox = ({ text, imagesArr, files, isRadiation, study, id, studyAuth }) => {
  return (
    <S2.ContentBox $printhide>
      {text && <TitleV3 title={text} />}
      <S.DropListWrap $radiation={isRadiation}>
        {imagesArr?.map((img) => (
          <S.DropList className={img} key={img}>
            <DropImageZone isRadiation={isRadiation} name={img} file={files?.[img]} study={study} id={id} studyAuth={studyAuth} />
          </S.DropList>
        ))}
      </S.DropListWrap>
    </S2.ContentBox>
  );
};

export default DragImageBox;

export const DropImageZone = ({ file, name, isRadiation, study, id, studyAuth }) => {
  const { t } = useTranslation(["translation"]);

  const [fileData, setFileData] = useState(file || null);
  //파일이 있거나, 최고관리자가 아니면 비활성화
  const [disabled, setDisabled] = useState(!studyAuth?.userPermission?.studySuperAuth || !!file);

  /**확대 이미지 선택 */
  const [imageViewer, setImageViewer] = useState({ imgSrc: [], isView: false });

  /**이미지 드래그앤 드랍 */
  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [] },
    multiple: false,
    disabled,
    onDrop: (acceptedFiles) => {
      const newFile = acceptedFiles[0];
      setFileData({
        file: newFile,
        preview: URL.createObjectURL(newFile),
      });
    },
  });

  /**이미지 등록 */
  const saveFile = usePostStudyImg(study, id);
  const handleSave = () => {
    if (fileData.file) {
      const formData = new FormData();
      formData.append("file", fileData.file);
      formData.append("position", name.toUpperCase());

      saveFile.mutate(formData, {
        onSuccess: () => {
          Notify.success(t(IK.update_success));
          setDisabled(true);
        },
      });
    }
  };

  /**이미지 삭제 */
  const deleteFile = useDeleteStudyImg(study, id);
  const handleOnClickClose = (deleteId) => {
    if (deleteId) {
      //파일이 있을 경우
      Notiflix.Confirm.show(t(IK.warning_delete_1), t(IK.warning_delete_2), t(IK.confirm), t(IK.cancel), () =>
        deleteFile.mutate(
          {
            [`${study}Id`]: id,
            [`${study}FileId`]: deleteId,
          },
          {
            onSuccess: () => {
              Notify.success(t(IK.delete_success));
              setFileData(null);
              setDisabled(false);
            },
          }
        )
      );
    } else {
      setFileData(null);
      setDisabled(false);
    }
  };

  /**이미지 회전 */
  const handleRotateImage = async (orientation) => {
    const rotatedImage = await rotateImage(fileData.file, orientation);
    setFileData(rotatedImage);
  };

  /** 이미지 다운로드 */
  const handleDownload = () => {
    if (file?.fileId) {
      downloadFile(file.fileId, file.orgFileName, t);
    }
  };

  /** 이미지 뷰어 열기 */
  const handleImageViewer = () => {
    if (file?.distFileName) {
      setImageViewer({ imgSrc: [file.distFileName], isView: true });
    }
  };

  return (
    <S.DropItem $radiation={isRadiation}>
      <S.DropItemZone {...getRootProps()}>
        <input {...getInputProps()} />
      </S.DropItemZone>

      {fileData && (
        <S.DropItemImg>
          <img src={file?.distFileName || fileData.preview} alt="preview" />
          {file?.fileId ? (
            <>
              <S.DropItemControlBtn type="button" onClick={() => handleDownload(file?.fileId, file?.orgFileName)} $downloadbtn>
                <i className="ri-download-line"></i>
              </S.DropItemControlBtn>
              <S.DropItemControlBtn type="button" onClick={() => handleImageViewer(file?.distFileName)} $enlargebtn>
                <i className="ri-fullscreen-line"></i>
              </S.DropItemControlBtn>
            </>
          ) : (
            <>
              <S.DropItemControlBtn type="button" onClick={() => handleRotateImage(6)} $refresh>
                <i className="ri-refresh-line" />
              </S.DropItemControlBtn>
              <S.DropItemControlBtn type="button" onClick={() => handleRotateImage(2)} $arrowbtn1>
                <i className="ri-arrow-left-right-line" />
              </S.DropItemControlBtn>
              <S.DropItemControlBtn type="button" onClick={() => handleRotateImage(4)} $arrowbtn2>
                <i className="ri-arrow-up-down-line" />
              </S.DropItemControlBtn>
              <S.DropItemControlBtn type="button" onClick={handleSave} $save>
                <i className="ri-save-fill" />
              </S.DropItemControlBtn>
            </>
          )}
          {studyAuth?.userPermission?.studySuperAuth && (
            <S.DropItemControlBtn type="button" onClick={() => handleOnClickClose(file?.studyFileId || file?.additionalFileId)} $close>
              <i className="ri-close-line" />
            </S.DropItemControlBtn>
          )}
        </S.DropItemImg>
      )}

      {imageViewer.isView && (
        <S2.ImageViewerBox>
          <ImageViewer
            src={imageViewer?.imgSrc}
            currentIndex={0}
            onClose={() => setImageViewer({ isView: false })}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.6)",
            }}
            closeOnClickOutside={true}
          />
        </S2.ImageViewerBox>
      )}
    </S.DropItem>
  );
};
