import dayjs from "dayjs";
import Notiflix, { Confirm } from "notiflix";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import ImageViewer from "react-simple-image-viewer";
import { getFileImageUrl } from "../../../apis/downloadApi";
import { useDeleteDesignComment, useEditDesignComment, useGetSetupCommentList, useNewDesignComment } from "../../../apis/studyApi";
import * as S3 from "../../../elements/Button/style/ButtonLayout.style";
import * as S2 from "../../../elements/Form/style/Form.style";
import * as S from "../../../styles/Common";
import { IK } from "../../../utils/i18n/keyStore";
import * as S4 from "../style/Study.style";

const StudyViewCommentList = ({ studyDesignId, studyAuth }) => {
  const { t } = useTranslation(["translation"]);

  const { data, isLoading } = useGetSetupCommentList(studyDesignId);
  const commentList = data?.data;

  return (
    <>
      {isLoading ? (
        <>
          <br />
          <Skeleton height={230} />
        </>
      ) : (
        <>
          <StudyViewComments title={t(IK.vip_comments)} isVip commentList={commentList?.vipComment} studyDesignId={studyDesignId} studyAuth={studyAuth} />
        </>
      )}
    </>
  );
};

export default StudyViewCommentList;

/**코멘트 입력창 + 코멘트 표출창 */
export const StudyViewComments = ({ title, isVip, studyDesignId, commentList, studyAuth }) => {
  const { t } = useTranslation(["translation"]);
  const { register, handleSubmit, reset } = useForm();

  /**코멘트 등록 뮤테이션 */
  const newCommentMutation = useNewDesignComment();

  /**코멘트 등록요청 */
  const submitComment = (data) => {
    //공백 입력시 무시
    if (data.comment?.trim() === "") return;
    newCommentMutation.mutate(
      { studyDesignId, comment: data.comment, isVip: isVip },
      {
        onSuccess: () => {
          reset();
        },
      }
    );
  };

  return (
    <S.ContentBox>
      <S.ListGroup>
        <dt>{title}</dt>
        <dd>
          <form onSubmit={handleSubmit(submitComment)}>
            <S2.FormControlsColumn>
              <S2.FormControls
                as="textarea"
                $textarea
                $full
                type="text"
                {...register("comment", {
                  required: true,
                })}
              />
              <S3.ButtonFlex $small>
                <S3.StyledSmallButton as="button">{t(IK.registration)}</S3.StyledSmallButton>
              </S3.ButtonFlex>
            </S2.FormControlsColumn>
          </form>
          {commentList?.map((comment) => (
            <StudyViewComment key={comment.commentId} comment={comment} t={t} isVip={isVip} studyAuth={studyAuth} />
          ))}
        </dd>
      </S.ListGroup>
    </S.ContentBox>
  );
};

/**코멘트 단건 */
export const StudyViewComment = ({ comment, t, isVip, studyAuth }) => {
  const { register, handleSubmit } = useForm();

  /**코멘트 삭제 뮤테이션 */
  const deleteCommentMutation = useDeleteDesignComment();

  /**코멘트 수정 뮤테이션 */
  const editCommentMutation = useEditDesignComment();

  /**코멘트 수정여부 */
  const [edit, setEdit] = useState(false);

  /**코멘트 삭제요청 */
  const deleteComment = () => {
    Confirm.show(
      t(IK.warning_delete_1),
      t(IK.warning_delete_2),
      t(IK.confirm),
      t(IK.cancel),
      () => {
        deleteCommentMutation.mutate({ isVip: isVip, commentId: comment.commentId });
      },
      () => {}
    );
  };

  /**코멘트 수정 */
  const submitRecomment = (data) => {
    //공백 입력시 무시
    if (data.comment?.trim() === "") return;
    editCommentMutation.mutate(
      { commentId: comment.commentId, comment: data.comment, isVip: isVip },
      {
        onSuccess: () => {
          setEdit(false);
        },
      }
    );
  };

  return (
    <S.ContentBox key={comment.commentId}>
      {edit ? (
        <form onSubmit={handleSubmit(submitRecomment)}>
          <S2.FormControlsColumn>
            <S2.FormControls
              as="textarea"
              $textarea
              $full
              type="text"
              defaultValue={comment.content}
              {...register("comment", {
                required: true,
              })}
            />
            <S3.ButtonFlex $small>
              <S3.StyledSmallButton as="button">{t(IK.registration)}</S3.StyledSmallButton>
              <S3.StyledSmallButton as="button" $secondary type="button" onClick={() => setEdit(false)}>
                {t(IK.cancel)}
              </S3.StyledSmallButton>
            </S3.ButtonFlex>
          </S2.FormControlsColumn>
        </form>
      ) : (
        <S2.FormControlsColumn>
          {studyAuth?.userPermission?.writerShow && studyAuth?.isKorea ? `[${comment.name}] ${t(IK.user_comment)}  ` : ""}
          {dayjs(comment.createdDate).format("YY/MM/DD HH:mm")}
          <CommentContent comment={comment.content} t={t} />
          {studyAuth?.userPermission?.studySuperAuth && (
            <S3.ButtonFlex $small>
              <S3.StyledSmallButton as="button" type="button" onClick={() => setEdit(true)}>
                {t(IK.modify)}
              </S3.StyledSmallButton>
              <S3.StyledSmallButton as="button" $secondary type="button" onClick={deleteComment}>
                {t(IK.delete)}
              </S3.StyledSmallButton>
            </S3.ButtonFlex>
          )}
        </S2.FormControlsColumn>
      )}
    </S.ContentBox>
  );
};

export const CommentContent = ({ comment, t }) => {
  //이미지 아이디 추출
  const regex = /#\{\{(\d+)\}\}#/;

  /**확대 이미지 선택 */
  const [imageViewer, setImageViewer] = useState({ imgSrc: [], isView: false });

  /**이미지 뷰어 열기 */
  const handleImageViewer = (fileID) => {
    getFileImageUrl(fileID)
      .then((res) => {
        setImageViewer({ imgSrc: [res], isView: true });
      })
      .catch((error) => {
        Notiflix.Report.failure(t(IK.image_error_txt_1), t(IK.image_error_txt_2), t(IK.confirm));
      });
  };

  return (
    <S4.StudyComment>
      {comment?.split(regex).map((part, index) =>
        index % 2 === 0 ? (
          <span key={index}>{part}</span>
        ) : (
          <button key={index} onClick={() => handleImageViewer(part)}>
            📷︎
          </button>
        )
      )}

      {imageViewer.isView && (
        <S.ImageViewerBox>
          <ImageViewer
            src={imageViewer?.imgSrc}
            currentIndex={0}
            onClose={() => setImageViewer({ isView: false })}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.6)",
            }}
            closeOnClickOutside={true}
          />
        </S.ImageViewerBox>
      )}
    </S4.StudyComment>
  );
};
