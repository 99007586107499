import { Confirm } from "notiflix";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useRecoilValue } from "recoil";
import { downloadFile } from "../../../apis/downloadApi";
import { getAminLink, useDeleteDesignFile, useDesignFileApproval, useGetSetupDesignFile, useReSetUpDesignFile, useShowDesignFileToVip, useStudyDesignCancel } from "../../../apis/studyApi";
import { userAuth } from "../../../atom/userAtom";
import * as S from "../../../elements/Button/style/ButtonLayout.style";
import * as S2 from "../../../elements/Form/style/Form.style";
import * as S3 from "../../../styles/Common";
import { isSuperAdminDesign } from "../../../utils/centerStatus";
import { IK } from "../../../utils/i18n/keyStore";
import { CardTail } from "../../layout/CardLayout/CardLayout";
import StudyViewCommentList from "./StudyViewCommentList";

const StudyViewDesignCustom = ({ studyId, isNowStudy, additionalId, detail, studyInfo, designFile, setDesignFile, clickDesignFileId, studyAuth, isConfirm }) => {
  const { t } = useTranslation(["translation"]);

  const { data, isLoading } = useGetSetupDesignFile(studyId, clickDesignFileId);
  const designItem = data?.data;

  const userRole = useRecoilValue(userAuth);

  /**셋업 파일 재등록  */
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setDesignFile(selectedFile);
    }
  };

  const reSetUpDesignMutation = useReSetUpDesignFile();
  /**셋업 파일 재등록 요청*/
  const handleEditDesignFile = () => {
    if (!!designFile) {
      const formData = new FormData();
      formData.append("setupDesign ", designFile);
      reSetUpDesignMutation.mutate({
        studyId,
        studyDesignId: designItem.studyDesignId,
        formData,
      });
    }
  };

  /**세라뷰 링크 조회 */
  const handleSeraviewLink = (type) => {
    const designPayload = {
      patientId: studyInfo.patientId,
      studyId,
      designId: designItem.studyDesignId,
      type,
      web: "admin",
    };

    if (additionalId) {
      designPayload.additionalId = additionalId;
    }

    getAminLink(designPayload, t).then((res) => {
      window.open(res.data, "_blank");
    });
  };

  const designFileDeleteMutation = useDeleteDesignFile();
  /**세라뷰 디자인 파일 삭제 */
  const deleteDesignFile = () => {
    Confirm.show(
      t(IK.warning_delete_1),
      t(IK.warning_delete_2),
      t(IK.confirm),
      t(IK.cancel),
      () => {
        designFileDeleteMutation.mutate({ studyId, studyDesignId: designItem.studyDesignId });
      },
      () => {}
    );
  };

  const studyCancelDesignMutation = useStudyDesignCancel();
  /**세라뷰 치료계획 승인됨 취소(디자인 취소) */
  const cancelDesign = () => {
    Confirm.show(
      t(IK.study_status_change),
      detail === "17" ? t(IK.study_cancel_1) : t(IK.study_cancel_2),
      t(IK.confirm),
      t(IK.cancel),
      () => {
        studyCancelDesignMutation.mutate(designItem.studyDesignId);
      },
      () => {}
    );
  };

  const studyDesignArbitraryMutation = useDesignFileApproval();
  /**세라뷰 임의승인 (최고관리자만) */
  const arbitraryApproval = () => {
    Confirm.show(
      t(IK.arbitrary_approval_confirm),
      "",
      t(IK.arbitrary_approval),
      t(IK.cancel),
      () => {
        studyDesignArbitraryMutation.mutate(designItem.studyDesignId);
      },
      () => {}
    );
  };

  const showDesignFileMutation = useShowDesignFileToVip();
  /**세라뷰 VIP 표출 여부 수정 */
  const handleShowDesignFile = () => {
    showDesignFileMutation.mutate({ studyDesignId: designItem.studyDesignId, showYn: designItem.showYn === "Y" ? false : true });
  };

  return (
    <>
      {isLoading ? (
        <Skeleton height={190} />
      ) : (
        <>
          <S3.ListGroup>
            <dt>{t(IK.design_file)}</dt>
            <dd>
              <S2.FormControlsFlex $colCenter>
                <S2.FormControls disabled type="text" value={designFile?.name || designItem?.orgFileName} />
                <S2.FormControls type="file" accept=".zip, application/zip" id="setupDesign_file" hidden onChange={handleFileChange} />
                {isSuperAdminDesign(userRole, detail, isNowStudy) && studyAuth?.userPermission?.studyDesignFix && (
                  <>
                    <S2.FormControlsBtn as="label" htmlFor="setupDesign_file">
                      {t(IK.modify)}
                    </S2.FormControlsBtn>
                    {!!designFile && (
                      <S.StyledButton as="button" $primary onClick={handleEditDesignFile}>
                        {t(IK.reregistration)}
                      </S.StyledButton>
                    )}
                  </>
                )}
              </S2.FormControlsFlex>

              <CardTail line>
                <S.ButtonLtBox>
                  {studyAuth?.userPermission?.studyDesignDownload && (
                    <S.StyledButton as="button" $secondary onClick={() => downloadFile(designItem.fileId, designItem.orgFileName, t)}>
                      {t(IK.design_file)} {t(IK.download)} <i className="ri-file-download-line"></i>
                    </S.StyledButton>
                  )}

                  <S.StyledButton as="button" $secondary onClick={() => handleSeraviewLink("SERAVIEW")}>
                    {t(IK.seraview)} <i className="ri-share-box-fill"></i>
                  </S.StyledButton>

                  <S.StyledButton as="button" $secondary onClick={() => handleSeraviewLink("SERAVIEW_WEB")}>
                    {t(IK.seraview)} WEB <i className="ri-share-box-fill"></i>
                  </S.StyledButton>
                  {/**디자인 승인 취소
                   * 승인된 디자인이 있음 &&  20이하의 상태 && 최고관리자
                   */}
                  {designItem?.confirm === 1 && detail <= "20" && studyAuth?.userPermission?.studySuperAuth && (
                    <S.StyledButton as="button" $primary onClick={cancelDesign}>
                      {t(IK.design_file_cancel)}
                    </S.StyledButton>
                  )}
                  {/** 디자인 임의승인
                   * 16-20까지 && 최고관리자 && 승인된 디자인 0개 && 미표출이 아닐때  */}
                  {detail >= "16" && detail <= "20" && studyAuth?.userPermission?.studySuperAuth && !isConfirm && designItem?.showYn === "Y" && (
                    <S.StyledButton as="button" $primary onClick={arbitraryApproval}>
                      {t(IK.arbitrary_approval)}
                    </S.StyledButton>
                  )}

                  {/**디자인 미표출
                   * 검토요청 상태 && 최고관리자 && 3D 수정상태 아님 && 승인됨 상태가 아닐때  */}
                  {detail === "16" && studyAuth?.userPermission?.studySuperAuth && studyInfo?.reviseStudyDesignYn !== "Y" && designItem?.confirm !== 1 && (
                    <S.StyledButton as="button" $primary onClick={handleShowDesignFile}>
                      {designItem?.showYn === "Y" ? t(IK.design_file_not_show) : t(IK.design_file_show)}
                    </S.StyledButton>
                  )}

                  {studyAuth?.userPermission?.studySuperAuth && detail === "15" && (
                    <S.StyledButton as="button" $primary onClick={deleteDesignFile}>
                      {t(IK.design_study)} {t(IK.delete)}
                    </S.StyledButton>
                  )}
                </S.ButtonLtBox>
              </CardTail>
            </dd>
          </S3.ListGroup>
          <StudyViewCommentList studyDesignId={designItem?.studyDesignId} studyAuth={studyAuth} />
        </>
      )}
    </>
  );
};

export default StudyViewDesignCustom;
