import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useGetSetupDesignList } from "../../../apis/studyApi";
import { IK } from "../../../utils/i18n/keyStore";
import * as S from "../style/Study.style";
import StudyViewDesignCustom from "./StudyViewDesignCustom";
import { StudyDesignFileUpload } from "./StudyViewDesignUpload";

const StudyViewSetup = ({ detail, studyId, studyInfo, additionalId, isNowStudy, studyAuth }) => {
  const { t } = useTranslation(["translation"]);

  /**디자인 파일리스트 조회 */
  const { data, isLoading } = useGetSetupDesignList(studyId, additionalId);
  const designList = data?.data;

  /**파일이동 버튼 클릭*/
  const [clickDesignFileId, setClickDesignFileId] = useState(0);

  /**디자인 리스트가 있을때 마운트시 제일 마지막 파일로 키값 설정(승인된 파일이 있을때는 승인 파일로) */
  useEffect(() => {
    if (!isLoading && designList && designList.length > 0) {
      const index = designList.length - 1;
      //승인된 파일이 있을때
      if (designList.some((item) => item.confirm)) {
        const confirmIndex = designList.findIndex((item) => item.confirm);
        setClickDesignFileId(designList[confirmIndex]["studyDesignId"]);
      } else {
        setClickDesignFileId(designList[index]["studyDesignId"]);
      }
    }
  }, [designList, isLoading]);

  /**버튼 이동시 파일수정 데이터 초기화 */
  const changeBtnClick = (id) => {
    setDesignFile(null);
    setClickDesignFileId(id);
  };

  /**새로운 파일 등록 버튼 클릭시 데이터 초기화 */
  const changeBtnClickInit = () => {
    setDesignFile(null);
    setClickDesignFileId(0);
  };

  /**디자인 파일 수정 */
  const [designFile, setDesignFile] = useState(null);

  /**디자인파일에서 승인 된게 있는지 체크 */
  const isConfirm = designList?.some((item) => item.confirm === 1);

  return (
    <>
      {isLoading ? (
        <Skeleton height={300} />
      ) : (
        <>
          {designList?.length < 1 ? (
            studyAuth?.userPermission?.studyDesign && <StudyDesignFileUpload studyId={studyId} designFile={designFile} setDesignFile={setDesignFile} />
          ) : (
            <>
              <S.StudyDesignTab>
                {designList.map((item, index) => (
                  <S.StudyDesignTabBtn key={item.studyDesignId} $primary={clickDesignFileId === item.studyDesignId} onClick={() => changeBtnClick(item.studyDesignId)}>
                    {`${index + 1}`} {item.isCenter === "Y" ? "CR" : "DR"} {item.confirm ? t(IK.status_apporve) : ""}
                  </S.StudyDesignTabBtn>
                ))}
                {detail === "15" && isNowStudy && studyAuth?.userPermission?.studyDesign && (
                  <S.StudyDesignTabBtn $secondary $primary={clickDesignFileId === 0} onClick={changeBtnClickInit}>
                    {clickDesignFileId === 0 ? (
                      t(IK.new_design)
                    ) : (
                      <>
                        {t(IK.add_design)} <i className="ri-add-line"></i>
                      </>
                    )}
                  </S.StudyDesignTabBtn>
                )}
              </S.StudyDesignTab>
              {clickDesignFileId === 0 ? (
                <StudyDesignFileUpload studyId={studyId} designFile={designFile} setDesignFile={setDesignFile} studyAuth={studyAuth} />
              ) : (
                <StudyViewDesignCustom
                  studyId={studyId}
                  additionalId={additionalId}
                  designFile={designFile}
                  setDesignFile={setDesignFile}
                  detail={detail}
                  isNowStudy={isNowStudy}
                  studyInfo={studyInfo}
                  clickDesignFileId={clickDesignFileId}
                  studyAuth={studyAuth}
                  isConfirm={isConfirm}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default StudyViewSetup;
