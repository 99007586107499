import { useRecoilValue } from "recoil";
import { userAuth } from "../atom/userAtom";
import { isPermissionStatus } from "../utils/centerStatus";

/**권한 확인
 * @param {string} status 페이지 권한
 * @returns {object} userPermission: 사용자 권한 여부, countryStatus: 국가 상태
 */
export const usePermissionCheck = (status) => {
  const { roleNames, countryCode } = useRecoilValue(userAuth);

  const userPermission = isPermissionStatus(roleNames, status);

  const countryStatus = {
    isAll: countryCode === "00",
    isKorea: countryCode === "01" || countryCode === "00",
  };

  return { userPermission, ...countryStatus };
};
