import { IK } from "./i18n/keyStore";

export const permissions = [
  { value: "", text: IK.select_permissions },
  { value: "SUPER_ADMIN", text: IK.admin_super }, //최고 관리자
  { value: "FACTORY_ADMIN", text: IK.admin_factory }, //제조소 관리자
  { value: "SALES_ADMIN", text: IK.admin_sales }, //영업 관리자
  { value: "ADVICE_ADMIN", text: IK.admin_advice }, //자문의 관리자
  { value: "EDUCATION_ADMIN", text: IK.admin_education }, //교육 관리자
  { value: "BUSINESS_ADMIN", text: IK.admin_business }, //경영 관리자
  { value: "DENTIS_ADMIN", text: IK.admin_dentis }, //덴티스
];

/**
 * 권한별 페이지 접근 권한
 * 사이트 객체 하위는 버튼 여부
 * */
export const permissionsCheck = {
  SUPER_ADMIN: {
    read: ["studyList", "patientList", "doctorList", "centerList", "couponList", "billList", "education", "support"],
    studyList: {
      /**
       * <----------------최고관리자만 가능한 권한------------->
       * - 본 처방전 삭제
       * - 추가 처방전 삭제
       * - 대체교정장치 삭제
       * - 디자인 승인 취소
       * - 디자인 임의 승인
       * - 디자인 삭제
       * - 디자인 VIP 쪽에 표출여부 수정
       * - 코멘트 삭제/수정
       * - 처방전 치료 시작일 수정
       * - 처방전 치료 옵션(패키지) 수정
       * - 처방전 이미지 수정/삭제
       * */
      studySuperAuth: true,
      /**처방전 플래너/자문의 수정 */
      studyManageFix: true,
      /**처방전 담당직원 숨김 */
      studyManagerShow: true,
      /**치료 시작일, 만료일, 추가주문만료일, 디자인 예상일 수정 */
      studyDateFix: true,
      /**처방전 악궁 항목 수정 */
      studyArchFix: true,
      /**처방전 특별지시사항 수정 */
      studySpecialFix: true,
      /**처방전 애브리텐 수정 */
      studyEvery10fix: true,
      /**내비게이션 의사 이동 가능 */
      naviDoctor: true,
      /**처방전 상태 수정 */
      studyDetailFix: true,
      /**처방전 디자인 파일 등록 */
      //최고관리자는 15(디자인 중) 이상에 상시 수정가능
      studyDesign: true,
      /**처방전 디자인 다운로드 */
      studyDesignDownload: true,
      /**디자인 파일 수정 */
      studyDesignFix: true,
      /**등록자 보임 */
      writerShow: true,
    },
    patientList: {
      /**환자 삭제 */
      patientDelete: true,
      /**환자 정보 수정 */
      patientInfoFix: true,
    },
    doctorList: {
      /**의사 삭제 */
      doctorDelete: true,
      /**코인 충전/차감 */
      doctorCoin: true,
      /**의사 할인율 수정 */
      doctorDiscount: true,
    },
    centerList: {
      /**센터 삭제 */
      centerDelete: true,
    },
  },
  FACTORY_ADMIN: {
    //제조소 관리자
    read: ["studyList"],
    studyList: {
      studySuperAuth: false,
      studyManageFix: true,
      studyManagerShow: true,
      studyDateFix: true,
      studyArchFix: false,
      studySpecialFix: false,
      studyEvery10fix: true,
      naviDoctor: false,
      studyDetailFix: true,
      studyDesign: true,
      studyDesignFix: true,
      studyDesignDownload: true,
      writerShow: true,
    },
  },
  SALES_ADMIN: {
    //영업 관리자
    read: ["studyList", "patientList", "doctorList"],
    studyList: {
      studySuperAuth: false,
      studyManageFix: false,
      studyManagerShow: true,
      studyDateFix: false,
      studyArchFix: false,
      studySpecialFix: false,
      studyEvery10fix: false,
      naviDoctor: true,
      studyDetailFix: false,
      studyDesign: false,
      studyDesignFix: false,
      studyDesignDownload: false,
      writerShow: true,
    },
    patientList: {
      patientDelete: false,
      patientInfoFix: true,
    },
    doctorList: {
      doctorDelete: false,
      doctorCoin: true,
      doctorDiscount: true,
    },
  },
  ADVICE_ADMIN: {
    //자문의 관리자
    read: ["studyList"],
    studyList: {
      studySuperAuth: false,
      studyManageFix: true,
      studyManagerShow: true,
      studyDateFix: false,
      studyArchFix: false,
      studySpecialFix: false,
      studyEvery10fix: false,
      naviDoctor: false,
      studyDetailFix: false,
      studyDesign: false,
      studyDesignFix: false,
      studyDesignDownload: true,
      writerShow: true,
    },
  },
  EDUCATION_ADMIN: {
    //C/S 관리자
    read: ["studyList", "patientList", "doctorList", "education", "support"],
    studyList: {
      studySuperAuth: false,
      studyManageFix: false,
      studyManagerShow: true,
      studyDateFix: true,
      studyArchFix: true,
      studySpecialFix: true,
      studyEvery10fix: true,
      naviDoctor: true,
      studyDetailFix: false,
      studyDesign: false,
      studyDesignFix: false,
      studyDesignDownload: true,
      writerShow: true,
    },
    patientList: {
      patientDelete: false,
      patientInfoFix: true,
    },
    doctorList: {
      doctorDelete: false,
      doctorCoin: true,
      doctorDiscount: true,
    },
  },
  BUSINESS_ADMIN: {
    //경영 관리자
    read: ["studyList", "patientList", "doctorList", "couponList", "billList"],
    studyList: {
      studySuperAuth: false,
      studyManageFix: false,
      studyManagerShow: true,
      studyDateFix: false,
      studyArchFix: false,
      studySpecialFix: false,
      studyEvery10fix: false,
      naviDoctor: true,
      studyDetailFix: false,
      studyDesign: false,
      studyDesignFix: false,
      studyDesignDownload: false,
      writerShow: true,
    },
    patientList: {
      patientDelete: false,
      patientInfoFix: true,
    },
    doctorList: {
      doctorDelete: false,
      doctorCoin: true,
      doctorDiscount: true,
    },
  },
  DENTIS_ADMIN: {
    //덴티스
    read: ["studyList", "patientList", "doctorList"],
    studyList: {
      studySuperAuth: false,
      studyManageFix: false,
      studyManagerShow: false,
      studyDateFix: false,
      studyArchFix: false,
      studySpecialFix: false,
      studyEvery10fix: false,
      naviDoctor: true,
      studyDetailFix: false,
      studyDesign: false,
      studyDesignFix: false,
      studyDesignDownload: false,
      writerShow: false,
    },
    patientList: {
      patientDelete: false,
      patientInfoFix: false,
    },
    doctorList: {
      doctorDelete: false,
      doctorCoin: false,
      doctorDiscount: false,
    },
  },
};

/**Read => 접근 여부를 1차적으로 판단 (true: 접근 가능, false: 접근 불가능) //false일 경우 하위 판단 x, 서버에서 403 에러 발생 */
export const isPermissionRead = (permissionLabels = [], pageRouter) => {
  // permissionLabels: 권한 라벨 배열
  // pageRouter: 현재 페이지의 라우터

  // 권한 라벨이 배열일 경우, 배열의 요소 중 하나라도 접근 가능하면 true
  const userPermissions = [].concat(...permissionLabels?.map((label) => permissionsCheck[label]?.read || []));
  const page = pageRouter.split("/")[1];

  return userPermissions.includes(page);
};

/** 접근 후 권한 판단 */
export const isPermissionStatus = (permissionLabels = [], page) => {
  // permissionLabels: 권한 라벨 배열
  // pageRouter: 현재 페이지의 라우터

  // 권한 라벨 배열에 해당하는 객체들을 가져오기
  // const permissions = permissionLabels?.map((label) => permissionsCheck[label][page]);
  // const combinedPermissions = mergeObjects(permissions);

  const label = permissionLabels[0];
  return permissionsCheck[label]?.[page] || {};
};

//중복권한 우려해서 만든것
// function mergeObjects(objects) {
//   // 객체를 돌면서 키값이 같은 것들을 합침
//   // true가 하나라도 있으면 true, 아니면 false
//   return objects.reduce((result, obj) => {
//     Object.keys(obj).forEach((key) => {
//       result[key] = result[key] || obj[key];
//     });
//     return result;
//   }, {});
// }

/**detail 15 디자인 중 이후에도 최고관리자는 디자인 수정 가능 */
export const isSuperAdminDesign = (role, detail, isNowStudy) => {
  const isSuperAdmin = role?.roleNames?.includes("SUPER_ADMIN");
  //현재 처방전의 경우 15(디자인 중) 이상인 경우에만 수정 가능 그외 최고관리자는 모두 가능
  return isSuperAdmin ? (isNowStudy ? detail >= "15" : true) : detail === "15" && isNowStudy;
};

/** 테이블 헤더, 컬럼 */
export const authStudyTableCustom = (studyAuth, t) => {
  // 기본 설정 (모든 열과 헤더 포함)
  const baseConfig = {
    col: ["80px", "*", "*", "100px", "*", "100px", "150px", "100px", "100px", "100px", "60px"],
    head: ["No.", t(IK.doctor_name), t(IK.patient_name), t(IK.treatment_option), t(IK.status), t(IK.create_date), t(IK.end_date), t(IK.study_staff), t(IK.study_advisory), "every10", t(IK.reorder)],
  };

  if (!studyAuth?.userPermission?.studyManagerShow && studyAuth?.isKorea) {
    // studyManagerShowr가 false고 한국계정인 경우 (study_staff, study_advisory 숨김)
    return {
      col: baseConfig.col.slice(0, 7).concat("60px", "60px"),
      head: baseConfig.head.slice(0, 7).concat("every10", t(IK.reorder)),
    };
  }

  if (!studyAuth?.isKorea) {
    // 해외계정인 경우 (study_staff, study_advisory, every10 숨김)
    return {
      col: baseConfig.col.slice(0, 7).concat("60px"),
      head: baseConfig.head.slice(0, 7).concat(t(IK.reorder)),
    };
  }

  // 기본 설정 반환
  return baseConfig;
};
