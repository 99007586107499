import React from "react";
import { useTranslation } from "react-i18next";
import * as S3 from "../../elements/Button/style/ButtonLayout.style";
import { FormControlsBox } from "../../elements/Form/FormLayout";
import * as S2 from "../../elements/Form/style/Form.style";
import { IK } from "../../utils/i18n/keyStore";

const ClinicalFile = ({ title, setValue, name, value, isImg, disabled, setDeleteClinicalFileIds, deleteClinicalFileIds }) => {
  const { t } = useTranslation(["translation"]);

  const fileInputRef = React.useRef(null);

  const handleChange = (event) => {
    setValue(name, event.target.files[0]);
  };

  const handleDelete = () => {
    // 입력 필드 값 초기화
    setValue(name, "");

    // 기존 파일 삭제 처리
    const boardFileId = value?.clinicalFileId;
    if (boardFileId && !deleteClinicalFileIds.includes(boardFileId)) {
      setDeleteClinicalFileIds([...deleteClinicalFileIds, boardFileId]);
    }

    // 파일 선택 input 초기화
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // input 값 비우기
    }
  };

  return (
    <FormControlsBox title={title}>
      <S2.FormControlsFlex>
        <S2.FormControls disabled value={value.name || value.orgFileName || ""} />
        <S2.FormControls type="file" id={name} accept={isImg ? "image/*" : "video/*"} hidden onChange={handleChange} disabled={disabled} ref={fileInputRef} />
        <S2.FormControlsBtn as="label" htmlFor={name}>
          {t(IK.file_btn)}
        </S2.FormControlsBtn>
        <S3.StyledButton as="button" $secondary type="button" onClick={handleDelete} disabled={disabled}>
          {t(IK.delete)}
        </S3.StyledButton>
      </S2.FormControlsFlex>
    </FormControlsBox>
  );
};

export default ClinicalFile;
