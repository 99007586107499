import dayjs from "dayjs";
import Notiflix from "notiflix";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useStudyDeliveryComplete,
  useStudyDeliveryStart,
  useStudyDesign,
  useStudyDesignConfirm,
  useStudyDeviceMaking,
  useStudyReceipt,
  useStudyReject,
  useStudyRejectDesign,
  useStudyReview,
} from "../../apis/studyApi";
import * as S3 from "../../components/Modal/style/Modal.style";
import * as S2 from "../../elements/Button/style/ButtonLayout.style";
import { TimePicker } from "../../elements/DatePicker";
import { FormControlsBox } from "../../elements/Form/FormLayout";
import * as S from "../../elements/Form/style/Form.style";
import { NotiMessage } from "../../elements/Message/MessageText";
import * as S4 from "../../elements/SelectionControls/style/SelectionControls.style";
import TextEditor from "../../elements/TextEditor";
import { IK } from "../../utils/i18n/keyStore";
import ModalLayout from "../Modal/ModalLayout";

const StudyStatusModal = ({ studyModal, setStudyModal, studyId, isSkipDesign }) => {
  const { t } = useTranslation(["translation"]);

  /**처방전 제출 => 처방전 접수로 변경 */
  const studyReceiptMutation = useStudyReceipt();
  /**처방전 접수 반려 상태로 변경 */
  const studyRejectMutation = useStudyReject();
  /**처방전 제출 => 세라뷰 디자인중으로 변경 */
  const studyDesignMutation = useStudyDesign();
  /**세라뷰 디자인 중 =>  세라뷰 검토요청 상태로 변경 */
  const studyReviewMutation = useStudyReview();
  /**세라뷰 검토요청 => 디자인중(디자인 재작업)으로 변경 */
  const studyRejectDesignMutation = useStudyRejectDesign();
  /**승인됨 => 세라뷰 장치 제작중으로 변경 */
  const studyDeviceMakingMutation = useStudyDeviceMaking();
  /**장치 제작중 => 배송중으로 변경 */
  const studyDeliveryStartMutation = useStudyDeliveryStart();
  /**배송중 => 배송완료로 변경 */
  const studyDeliveryCompleteMutation = useStudyDeliveryComplete();
  /**승인된 디자인을 재작업한 경우, 관리자에서 승인처리(단계 넘기기용) */
  const studyDesignConfirm = useStudyDesignConfirm();

  //study_status_receipt 디자인 예상일 3일
  //study_status_device_making 장치 제작 예상일 6일
  /**휴일 제외 날짜 지정  */
  const setDaysNumber = useMemo(() => {
    const { content } = studyModal;
    const numDays = content === "study_status_receipt" ? 3 : 4;
    let count = 0;
    let currentDate = new Date();
    while (count < numDays) {
      currentDate.setDate(currentDate.getDate() + 1);
      // 주말이 아닌 경우에만 count 증가
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        // 0은 일요일, 6은 토요일
        count++;
      }
    }
    return currentDate;
  }, [studyModal]);

  /**처방전 상태변경 데이터 관리 */
  const [studyStatus, setStudyStatus] = useState({
    check: true, //체크 표시 여부
    date: dayjs(setDaysNumber).format("YYYY-MM-DD HH:mm:ss"), //변경 날짜
    comment: "", //관리자 코멘트
    rejectReason: "", //반려 사유
  });

  /**모달 닫기 */
  const handleClose = () => {
    setStudyModal({ open: false });
  };

  /**체크박스 변경 */
  const handleCheck = () => {
    setStudyStatus((prev) => ({
      ...prev,
      check: !prev.check,
    }));
  };

  /**날짜 변경*/
  const handleDate = (dateValue) => {
    setStudyStatus((prev) => ({
      ...prev,
      date: dateValue ? dayjs(dateValue).format("YYYY-MM-DD HH:mm:ss") : new Date(),
    }));
  };

  /**글자수 체크 */
  const [contentLength, setContentLength] = useState({
    characters: 0,
    words: 0,
  });

  /**에디터 변경 */
  const handleEditor = (content) => {
    setStudyStatus((prev) => ({
      ...prev,
      comment: content,
    }));
  };

  const dataMap = {
    study_status_receipt: {
      mutation: studyReceiptMutation,
      data: {
        expectedDate: isSkipDesign ? null : studyStatus.date,
        sendMessage: studyStatus.check,
      },
    },
    study_status_reject: {
      mutation: studyRejectMutation,
      data: {
        rejectReason: studyStatus.rejectReason,
        sendMessage: studyStatus.check,
        comment: studyStatus.comment,
      },
    },
    study_status_design: {
      mutation: studyDesignMutation,
      data: {},
    },

    study_status_review: {
      mutation: studyReviewMutation,
      data: {
        sendMessage: studyStatus.check,
      },
    },
    study_status_reject_design: {
      mutation: studyRejectDesignMutation,
      data: {},
    },
    study_status_device_making: {
      mutation: studyDeviceMakingMutation,
      data: {
        expectedDate: studyStatus.date,
      },
    },
    study_status_delivery_start: {
      mutation: studyDeliveryStartMutation,
      data: {
        sendMessage: studyStatus.check,
      },
    },
    study_status_delivery_complete: {
      mutation: studyDeliveryCompleteMutation,
      data: {},
    },
    study_status_design_confirm: {
      mutation: studyDesignConfirm,
      data: {
        sendMessage: studyStatus.check,
      },
    },
  };

  /**확인 눌러서 치료상태 변경 */
  const handleConfirm = () => {
    const { mutation, data } = dataMap[studyModal.content];

    if (studyModal.content === "study_status_reject" && !studyStatus.rejectReason) {
      //반려사유 선택안한 경우 경고창
      return Notiflix.Report.info(t(IK.reject_reason_error), "", t(IK.confirm), () => {});
    }

    //반려처리에서 에디터에서 내용 미등록
    if (studyModal.content === "study_status_reject" && (contentLength.characters === 0 || contentLength.words === 0)) {
      return Notiflix.Report.info("", t(IK.board_placeholder), t(IK.confirm), () => {});
    }

    mutation.mutate({ studyId, data }, { onSuccess: handleClose });
  };

  const rejectList = ["REJECT_SCAN", "REJECT_MODEL", "REJECT_OCCLUSION", "REJECT_ETC"];

  /**반려사유 추가 */
  const handleReject = (e) => {
    setStudyStatus((prev) => ({
      ...prev,
      rejectReason: e.target.value,
    }));
  };

  return (
    <ModalLayout open={studyModal.open} header={t(IK[studyModal.title])} handleClose={handleClose}>
      <S.FormControlsContainer>
        <S3.ModalBoxBullet>
          <li>{t(IK[studyModal.content])}</li>
        </S3.ModalBoxBullet>

        {studyModal?.required?.isDatepicker && (
          <FormControlsBox regular title={studyModal.content === "study_status_receipt" ? t(IK.seraview_expected) : t(IK.expacted_date_device)}>
            <TimePicker handleDataChange={handleDate} selected={new Date(studyStatus.date)} formTimeControls />
          </FormControlsBox>
        )}

        {/* 반려 사유 */}
        {studyModal?.required?.isEditor && (
          <FormControlsBox required regular title={t(IK.companion_reason)}>
            <S4.SelectionItemList>
              {rejectList.map((item) => (
                <S4.SelectionItem key={item}>
                  <S4.SelectionItemLabel onChange={(e) => handleReject(e)}>
                    <S4.SelectionItemInput $radio type="radio" id={item} value={item} name="rejectReason" />
                    <S4.SelectionItemSpan htmlFor={item}>{t(IK[item.toLowerCase()])}</S4.SelectionItemSpan>
                  </S4.SelectionItemLabel>
                </S4.SelectionItem>
              ))}
            </S4.SelectionItemList>
          </FormControlsBox>
        )}

        {/* 메모 */}
        {studyModal?.required?.isEditor && (
          <FormControlsBox required regular title={t(IK.memo)}>
            <TextEditor setData={handleEditor} content={studyStatus.comment} isModal setContentLength={setContentLength} />
          </FormControlsBox>
        )}

        {/* 문자 발송 */}
        {studyModal?.required?.isCheckbox && (
          <FormControlsBox regular title={t(IK.message_submit)}>
            <S4.SelectionItem>
              <S4.SelectionItemLabel>
                <S4.SelectionItemInput $checkbox type="checkbox" defaultChecked={studyStatus.check} onChange={handleCheck} />
                <S4.SelectionItemSpan>{t(IK.message_status)}</S4.SelectionItemSpan>
              </S4.SelectionItemLabel>
            </S4.SelectionItem>
            <NotiMessage text={t(IK.message_noti)} />
          </FormControlsBox>
        )}
      </S.FormControlsContainer>
      <S3.ModalBoxBtn>
        <S2.ButtonFlex>
          <S2.StyledButton as="button" onClick={handleClose}>
            {t(IK.cancel)}
          </S2.StyledButton>
          <S2.StyledButton as="button" onClick={handleConfirm} $primary>
            {t(IK.confirm)}
          </S2.StyledButton>
        </S2.ButtonFlex>
      </S3.ModalBoxBtn>
    </ModalLayout>
  );
};

export default StudyStatusModal;

//
