import dayjs from "dayjs";
import { Confirm } from "notiflix";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { downloadFile } from "../../apis/downloadApi";
import { useGetSeraquickFile } from "../../apis/patientApi";
import { useEditPackages, useGetManagerList, useManagerRegistration } from "../../apis/studyApi";
import * as S3 from "../../elements/Button/style/ButtonLayout.style";
import * as S2 from "../../elements/Form/style/Form.style";
import ProfileImage from "../../elements/ProfileImage";
import * as S4 from "../../styles/Common";
import { IK } from "../../utils/i18n/keyStore";
import { countryObject } from "../../utils/searchLabel";
import { getConditionItem, studyStatusListLabel } from "../../utils/studyStatus";
import * as S from "../layout/CardLayout/style/CardLayout.style";
import PreferenceModal from "./PreferenceModal";
import PrescriptionModal from "./PrescriptionModal";
import StudyStatusModal from "./StudyStatusModal";
import StudyViewEditDate from "./studyContent/StudyViewEditDate";

const StudyViewInfo = ({ studyId, studyInfo, studyAuth }) => {
  const { t } = useTranslation(["translation"]);

  /**세라뷰 디자인 단계 스킵 */
  const isSkipDesign = studyInfo?.packages === "RP" || studyInfo?.additionalProcess === "none" || studyInfo?.deviceStatus === "alternative_correction_devices";
  /**임상환경 모달 */
  const [openPreference, setOpenPreference] = useState(false);
  /**처방전 내역 모달 */
  const [openPrescription, setOpenPrescription] = useState(false);

  /**처방전 상태변경 확인 모달 */
  const [studyModal, setStudyModal] = useState({
    open: false, //모달 표시 여부
    title: "", //타이틀
    content: "", //모달 내용
    required: {
      isDatepicker: !isSkipDesign, //날짜 설정
      isCheckbox: false, //문자 발송 여부
      isEditor: false, //에디터 설정
    },
  });

  /**담당자 수정 상태관리 */
  const [editManager, setEditManager] = useState({ advisoryId: studyInfo?.advisory?.accountId, managerId: studyInfo?.manager?.accountId, isEdit: false });

  useEffect(() => {
    setEditManager({ advisoryId: studyInfo?.advisory?.accountId, managerId: studyInfo?.manager?.accountId, isEdit: false });
  }, [studyInfo]);

  /**담당직원 조회 */
  const { data: managerData, isLoading: isManagerLoading } = useGetManagerList(editManager.isEdit);
  const managerList = managerData?.data;

  const managerMutation = useManagerRegistration();
  /**플래너+자문의  등록 */
  const changeManager = () => {
    managerMutation.mutate(
      {
        studyId,
        data: {
          advisoryId: editManager.advisoryId,
          managerId: editManager.managerId,
        },
      },
      {
        onSuccess: () => {
          setEditManager({
            isEdit: false,
          });
        },
      }
    );
  };

  /**환자의 세라퀵 파일 조회 */
  const { data: seraquickData } = useGetSeraquickFile(studyInfo?.patientId);

  /**세라퀵 파일 다운로드 */
  const handleSeraquickDownload = () => {
    downloadFile(seraquickData.fileId, seraquickData.orgFileName, t);
  };

  /**옵션변경관련 상태관리 */
  const packagesMutation = useEditPackages();
  const [editPackages, setEditPackages] = useState({
    isEdit: false,
    packages: studyInfo?.packages,
  });

  /**세라핀 패키지 옵션 변경 */
  const editPackagesOption = () => {
    packagesMutation.mutate(
      { studyId, code: editPackages.packages },
      {
        onSuccess: () => {
          setEditPackages({
            isEdit: false,
          });
        },
      }
    );
  };

  /**치료상태 처방전 제출 => 처방전 접수로 상태 변경 */
  const handleStudyReceipt = () => {
    const modalData = {
      open: true,
      title: "study_status_change",
      content: "study_status_receipt",
      required: {
        isDatepicker: true,
        isCheckbox: true,
        isEditor: false,
      },
    };

    //담당자가 없을 경우 팝업 표시
    if (!studyInfo?.advisory && !studyInfo?.manager) {
      return Confirm.show(
        t(IK.study_prescription_check_receipt_1),
        t(IK.study_prescription_check_receipt_2),
        t(IK.confirm),
        t(IK.cancel),
        () => {
          setStudyModal(modalData);
        },
        () => {}
      );
    }
    setStudyModal(modalData);
  };

  /**치료상태 처방전 반려 상태로 변경 */
  const handleStudyReject = () => {
    setStudyModal({
      open: true,
      title: "study_status_change",
      content: "study_status_reject",
      required: {
        isDatepicker: false,
        isCheckbox: true,
        isEditor: true,
      },
    });
  };

  /**처방전 접수 =>세라뷰 디자인중으로 상태변경 */
  const handleStudyDesignStart = () => {
    setStudyModal({
      open: true,
      title: "study_status_change",
      content: "study_status_design",
      required: {
        isDatepicker: false,
        isCheckbox: false,
        isEditor: false,
      },
    });
  };

  /**세라뷰 디자인 => 세라뷰 검토요청으로 변경 */
  const handleStudyReview = () => {
    setStudyModal({
      open: true,
      title: "study_status_change",
      content: "study_status_review",
      required: {
        isDatepicker: false,
        isCheckbox: true,
        isEditor: false,
      },
    });
  };

  /**검토요청 => 디자인 중(재작업)으로 상태 변경 */
  const handleStudyDesignReject = () => {
    setStudyModal({
      open: true,
      title: "study_status_change",
      content: "study_status_reject_design",
      required: {
        isDatepicker: false,
        isCheckbox: false,
        isEditor: false,
      },
    });
  };

  /**승인됨 => 장치제작중으로 치료상태 변경 */
  const changeDeviceMaking = () => {
    setStudyModal({
      open: true,
      title: "study_status_change",
      content: "study_status_device_making",
      required: {
        isDatepicker: true,
        isCheckbox: false,
        isEditor: false,
      },
    });
  };

  /**제작중 =>배송중으로 치료상태 변경 */
  const handleDeliveryStart = () => {
    setStudyModal({
      open: true,
      title: "study_status_change",
      content: "study_status_delivery_start",
      required: {
        isDatepicker: false,
        isCheckbox: true,
        isEditor: false,
      },
    });
  };

  /**배송중 => 배송완료로 치료상태 변경 */
  const handleDeliveryComplete = () => {
    setStudyModal({
      open: true,
      title: "study_status_change",
      content: "study_status_delivery_complete",
      required: {
        isDatepicker: false,
        isCheckbox: false,
        isEditor: false,
      },
    });
  };

  /**디자인 재작업 후 => 디자인 승인됨으로 치료상태 변경 */
  const handleStudyDesignConfirm = () => {
    setStudyModal({
      open: true,
      title: "study_status_change",
      content: "study_status_design_confirm",
      required: {
        isDatepicker: false,
        isCheckbox: true,
        isEditor: false,
      },
    });
  };

  return (
    <>
      <S.CardProfileWrapper>
        <S.CardProfileBox>
          <S.CardProfileHead>
            <S.CardProfileHeadInfo>
              <S.CardProfileHeadInfoImg>
                <div>
                  <ProfileImage profile={studyInfo?.profile} />
                </div>
              </S.CardProfileHeadInfoImg>
              <S.CardProfileHeadInfoTxt>
                <h2>{studyInfo?.patientName}</h2>
              </S.CardProfileHeadInfoTxt>
            </S.CardProfileHeadInfo>
            <S.CardProfileHeadBtnBottom>
              {seraquickData?.fileId && (
                <button type="button" onClick={handleSeraquickDownload}>
                  {t(IK.seraqucik_simulation)}
                </button>
              )}
              <button type="button" onClick={() => setOpenPrescription(true)}>
                {t(IK.study_history)}
              </button>
            </S.CardProfileHeadBtnBottom>
          </S.CardProfileHead>
          <S.CardProfileBodyInfo>
            <dl>
              <dt>{t(IK.birthday)}</dt>
              <dd>
                <span>
                  {studyInfo?.birthday} ({studyInfo?.patientAge})
                </span>
              </dd>
            </dl>
            <dl>
              <dt>
                {t(IK.doctor)} {t(IK.country)}
              </dt>
              <dd>
                <span>
                  {countryObject[studyInfo?.countryCode]} ({studyInfo?.patientRace})
                </span>
              </dd>
            </dl>
            <dl>
              <dt>{t(IK.clinical_condition)}</dt>
              <S4.ContentScroll as="dd" $small>
                <span>{getConditionItem(studyInfo?.conditions, t)}</span>
              </S4.ContentScroll>
            </dl>
            <dl>
              <dt>{t(IK.patient_memo)}</dt>
              <S4.ContentScroll as="dd" $small>
                <S4.TextDiv $textpreline>{studyInfo?.memo}</S4.TextDiv>
              </S4.ContentScroll>
            </dl>
            <dl>
              <dt>{t(IK.clinical_configurations)}</dt>
              <dd>
                <S3.StyledSmallButton as="button" type="button" onClick={() => setOpenPreference(true)}>
                  <S4.TextDiv $blink={studyInfo?.clinicItemEditYn === "Y"}>{t(IK.clinical_configurations_btn)}</S4.TextDiv>
                </S3.StyledSmallButton>
              </dd>
            </dl>
            <dl>
              <dt>{t(IK.treatment_option)}</dt>
              <dd>
                {editPackages.isEdit ? (
                  <S2.FormControlsFlex>
                    <S2.FormLineControls as="select" $select defaultValue={studyInfo?.packages} onChange={(e) => setEditPackages((prev) => ({ ...prev, packages: e.target.value }))}>
                      <option value="R">R-active</option>
                      <option value="RP">R-passive</option>
                      <option value="S10">10</option>
                      <option value="S20">20</option>
                      <option value="SR">Regular</option>
                      <option value="SAP">Regular AP</option>
                      <option value="Si">i</option>
                      <option value="SiAP">iAP</option>
                    </S2.FormLineControls>
                    <S3.ButtonFlex $small>
                      <S3.StyledSmallButton as="button" type="button" onClick={editPackagesOption}>
                        {t(IK.registration)}
                      </S3.StyledSmallButton>
                      <S3.StyledSmallButton as="button" type="button" $secondary onClick={() => setEditPackages((prev) => ({ ...prev, isEdit: false }))}>
                        {t(IK.cancel)}
                      </S3.StyledSmallButton>
                    </S3.ButtonFlex>
                  </S2.FormControlsFlex>
                ) : (
                  <S2.FormControlsFlex>
                    <span>{t(IK[studyInfo?.packages])}</span>
                    {studyAuth.userPermission.studySuperAuth && studyInfo?.detail <= 20 && (
                      <S3.StyledSmallButton as="button" type="button" onClick={() => setEditPackages((prev) => ({ ...prev, isEdit: true }))}>
                        {t(IK.modify)}
                      </S3.StyledSmallButton>
                    )}
                  </S2.FormControlsFlex>
                )}
              </dd>
            </dl>
            <dl>
              <dt>{t(IK.status)}</dt>
              <dd>
                <S2.FormControlsFlex>
                  <span>
                    {t(IK[studyInfo?.deviceStatus])}({studyInfo?.deviceCreatedDate ? dayjs(studyInfo?.deviceCreatedDate).format("YYYY-MM-DD") : ""}) {studyStatusListLabel(studyInfo, t)}
                  </span>
                  {studyAuth?.userPermission?.studyDetailFix && (
                    <S3.ButtonFlex $small>
                      {studyInfo?.detail === "13" && (
                        <S3.StyledSmallButton as="button" type="button" onClick={handleStudyReceipt}>
                          {t(IK.receipt_study)}
                        </S3.StyledSmallButton>
                      )}
                      {studyInfo?.detail === "14" && !isSkipDesign && (
                        <S3.StyledSmallButton as="button" type="button" onClick={handleStudyDesignStart}>
                          {t(IK.develope_study_plan)}
                        </S3.StyledSmallButton>
                      )}
                      {/**세라핀 RP, 추가처방전 업데이트 항목에서 아니오 선택시 접수 => 제작중으로 변경 */}
                      {studyInfo?.detail === "14" && isSkipDesign && (
                        <S3.StyledSmallButton as="button" type="button" onClick={changeDeviceMaking}>
                          {t(IK.progress_aligner)}
                        </S3.StyledSmallButton>
                      )}
                      {studyInfo?.detail === "15" && (
                        <S3.StyledSmallButton as="button" type="button" onClick={handleStudyReview}>
                          {t(IK.confirm_seraview)}
                        </S3.StyledSmallButton>
                      )}
                      {studyInfo?.detail >= 16 && studyInfo?.detail <= 20 && !isSkipDesign && (
                        <S3.StyledSmallButton as="button" type="button" onClick={handleStudyDesignReject}>
                          {t(IK.develope_study_replan)}
                        </S3.StyledSmallButton>
                      )}
                      {/**의사가 승인 눌러야 다음 상태로 진행 가능 승인시 detail 17/ 또는 승인 취소 없이 디자인 재작업 후 승인처리(confirmBtn) */}
                      {studyInfo?.confirmBtn && (
                        <S3.StyledSmallButton as="button" type="button" onClick={handleStudyDesignConfirm}>
                          {t(IK.approved_seraview)}
                        </S3.StyledSmallButton>
                      )}
                      {studyInfo?.detail === "17" && (
                        <S3.StyledSmallButton as="button" type="button" onClick={changeDeviceMaking}>
                          {t(IK.progress_aligner)}
                        </S3.StyledSmallButton>
                      )}
                      {studyInfo?.detail === "18" && (
                        <S3.StyledSmallButton as="button" type="button" onClick={handleDeliveryStart}>
                          {t(IK.transit_device)}
                        </S3.StyledSmallButton>
                      )}
                      {studyInfo?.detail === "19" && (
                        <S3.StyledSmallButton as="button" type="button" onClick={handleDeliveryComplete}>
                          {t(IK.shipped_device)}
                        </S3.StyledSmallButton>
                      )}
                      {studyInfo?.detail >= 13 && studyInfo?.detail <= 20 && (
                        <S3.StyledSmallButton as="button" type="button" $secondary onClick={handleStudyReject}>
                          {t(IK.reject_study)}
                        </S3.StyledSmallButton>
                      )}
                    </S3.ButtonFlex>
                  )}
                </S2.FormControlsFlex>
              </dd>
            </dl>
            {studyAuth?.userPermission?.studyManagerShow && studyAuth?.isKorea && (
              <dl>
                <dt>{t(IK.manager)}</dt>
                <dd>
                  {!isManagerLoading && editManager.isEdit ? (
                    <S2.FormControlsColumn>
                      <span>
                        {t(IK.study_advisory)} :
                        <S2.FormLineControls
                          as="select"
                          $select
                          defaultValue={studyInfo?.advisory?.accountId}
                          onChange={(event) => setEditManager((prev) => ({ ...prev, advisoryId: event.target.value }))}
                        >
                          <option value="">{t(IK.study_advisory_select)}</option>
                          {managerList?.advisory.map((item) => (
                            <option key={item.accountId} value={item.accountId}>
                              {item.name}({item.loginId})
                            </option>
                          ))}
                        </S2.FormLineControls>
                      </span>
                      <span>
                        {t(IK.study_staff)} :
                        <S2.FormLineControls
                          as="select"
                          $select
                          defaultValue={studyInfo?.manager?.accountId}
                          onChange={(event) => setEditManager((prev) => ({ ...prev, managerId: event.target.value }))}
                        >
                          <option value="">{t(IK.study_staff_select)}</option>
                          {managerList?.manager.map((item) => (
                            <option key={item.accountId} value={item.accountId}>
                              {item.name}({item.loginId})
                            </option>
                          ))}
                        </S2.FormLineControls>
                      </span>
                      <S3.ButtonFlex $small>
                        <S3.StyledSmallButton as="button" type="button" onClick={changeManager}>
                          {t(IK.registration)}
                        </S3.StyledSmallButton>
                        <S3.StyledSmallButton as="button" type="button" $secondary onClick={() => setEditManager((prev) => ({ ...prev, isEdit: false }))}>
                          {t(IK.cancel)}
                        </S3.StyledSmallButton>
                      </S3.ButtonFlex>
                    </S2.FormControlsColumn>
                  ) : (
                    <S2.FormControlsFlex $rowCenter>
                      <S2.FormControlsColumn>
                        <span>
                          {t(IK.study_advisory)} : {studyInfo?.advisory ? `${studyInfo?.advisory?.name}(${studyInfo?.advisory?.loginId})` : t(IK.none)}
                        </span>
                        <span>
                          {t(IK.study_staff)} : {studyInfo?.manager ? `${studyInfo?.manager?.name}(${studyInfo?.manager?.loginId})` : t(IK.none)}
                        </span>
                      </S2.FormControlsColumn>
                      {studyAuth?.userPermission?.studyManageFix && (
                        <S3.StyledSmallButton as="button" type="button" onClick={() => setEditManager((prev) => ({ ...prev, isEdit: true }))}>
                          {t(IK.modify)}
                        </S3.StyledSmallButton>
                      )}
                    </S2.FormControlsFlex>
                  )}
                </dd>
              </dl>
            )}
            <StudyViewEditDate studyAuth={studyAuth} studyId={studyId} studyInfo={studyInfo} />
          </S.CardProfileBodyInfo>
        </S.CardProfileBox>
      </S.CardProfileWrapper>

      {/**임상환경 모달 */}
      {openPreference && <PreferenceModal openPreference={openPreference} setOpenPreference={setOpenPreference} patientId={studyInfo?.patientId} />}

      {/**처방전 내역 모달 */}
      {openPrescription && (
        <PrescriptionModal
          openPrescription={openPrescription}
          setOpenPrescription={setOpenPrescription}
          patientId={studyInfo?.patientId}
          studyId={studyId}
          studyInfo={studyInfo}
          studyAuth={studyAuth}
        />
      )}
      {/**처방전 상태변경 관련 모달 */}
      {studyModal.open && <StudyStatusModal studyModal={studyModal} setStudyModal={setStudyModal} studyId={studyId} isSkipDesign={isSkipDesign} />}
    </>
  );
};

export default StudyViewInfo;
