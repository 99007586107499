import Notiflix from "notiflix";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCopyGallery, useGetGalleryList } from "../apis/boardApi";
import BoardTotal from "../components/board/BoardTotal";
import ClinicalGalleryTr from "../components/clinicalGallery/ClinicalGalleryTr";
import ClinicalSearchFilter from "../components/clinicalGallery/ClinicalSearchFilter";
import ClinicalTab from "../components/clinicalGallery/ClinicalTab";
import MemoModal from "../components/clinicalGallery/MemoModal";
import { CardHead, CardTail } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import * as S4 from "../elements/Button/style/ButtonLayout.style";
import DataTable from "../elements/DataTable";
import DataTableSkeleton from "../elements/DataTableSkeleton";
import { FormControlsFlex } from "../elements/Form/style/Form.style";
import Paging from "../elements/Paging";
import * as S2 from "../elements/SelectionControls/style/SelectionControls.style";
import { usePermissionCheck } from "../hooks/usePermissionCheck";
import { useSearchParam } from "../hooks/useSearchParam";
import { IK } from "../utils/i18n/keyStore";
import { changeParam } from "../utils/searchParams";
const CliniCalGallery = () => {
  const { t } = useTranslation(["translation"]);

  /**권한 조회 */
  const galleryAuth = usePermissionCheck("galleryList");

  /**검색 조건 설정*/
  const { searchCondition, setSearchCondition } = useSearchParam({ page: 1 });

  /**페이지 설정 */
  const handleChangePage = (page) => {
    setSearchCondition((prev) => ({ ...prev, page }));
  };

  const { data, isLoading } = useGetGalleryList(changeParam(searchCondition));
  const galleries = data?.data;

  /**체크박스 */
  const [checks, setChecks] = useState([]);

  /**전체 체크 */
  const handleAllCheck = () => {
    if (checks.length === galleries?.content.length) {
      setChecks([]);
    } else {
      setChecks(galleries?.content.map((item) => item.clinicalId));
    }
  };

  const galleryCopy = useCopyGallery();

  /**게시물 복사 */
  const handleCopy = () => {
    if (checks.length === 0) {
      return Notiflix.Report.warning(t(IK.board_copy_txt_1), t(IK.board_copy_txt_2), t(IK.confirm));
    }
    return Notiflix.Confirm.show(
      t(IK.board_copy_txt_3),
      t(IK.board_copy_txt_4),
      t(IK.confirm),
      t(IK.cancel),
      () =>
        galleryCopy.mutate(checks, {
          onSuccess: () => {
            setChecks([]);
          },
        }),
      () => {}
    );
  };

  const tableCol = galleryAuth?.isAll
    ? ["70px", "100px", "80px", "80px", "140px", "160px", "160px", "160px", "160px", "180px", "100px", "100px"]
    : ["100px", "80px", "80px", "140px", "160px", "160px", "160px", "160px", "100px", "100px"];
  const tableHead = galleryAuth?.isAll
    ? [
        <S2.SelectionItem $labelhidden>
          <S2.SelectionItemLabel>
            <S2.SelectionItemInput $checkbox type="checkbox" onChange={handleAllCheck} checked={checks.length > 0 && checks.length === galleries?.content?.length} />
          </S2.SelectionItemLabel>
        </S2.SelectionItem>,
        t(IK.clinical_image),
        t(IK.clinical_id),
        t(IK.is_copied),
        t(IK.clinical_is_submit),
        t(IK.clinic_name),
        t(IK.patient_name),
        t(IK.treatment_option),
        t(IK.register_date),
        t(IK.country),
        t(IK.coupon_btn),
        t(IK.memo),
      ]
    : [
        t(IK.clinical_image),
        t(IK.clinical_id),
        t(IK.is_copied),
        t(IK.clinical_is_submit),
        t(IK.clinic_name),
        t(IK.patient_name),
        t(IK.treatment_option),
        t(IK.register_date),
        t(IK.coupon_btn),
        t(IK.memo),
      ];

  /**메모 모달*/
  const [memoModal, setMemoModal] = useState({ isOpen: false, text: "" });

  return (
    <S.CardRow>
      <S.CardCol>
        <ClinicalSearchFilter setSearchCondition={setSearchCondition} searchCondition={searchCondition} t={t} isAllCountry={galleryAuth?.isAll} />
      </S.CardCol>
      {isLoading ? (
        <DataTableSkeleton tableCol={tableCol} tableHead={tableHead} />
      ) : (
        <S.CardCol>
          <S.CardInner>
            <ClinicalTab setSearchCondition={setSearchCondition} searchCondition={searchCondition} />
            <CardHead>
              <BoardTotal total={galleries?.totalElements} />
              {galleryAuth?.isAll && (
                <FormControlsFlex>
                  <S4.StyledButton $secondary onClick={handleCopy}>
                    {t(IK.board_copy)}
                  </S4.StyledButton>
                  <S4.StyledButton $primary to="/education/clinical-gallery/post">
                    {t(IK.registration)}
                  </S4.StyledButton>
                </FormControlsFlex>
              )}
            </CardHead>
            <DataTable
              tableCol={tableCol}
              dataLength={galleries?.totalElements}
              tableHead={tableHead}
              tableTr={<ClinicalGalleryTr isAllCountry={galleryAuth?.isAll} setMemoModal={setMemoModal} galleries={galleries?.content} checks={checks} setChecks={setChecks} />}
            />
            <MemoModal memoModal={memoModal} setMemoModal={setMemoModal} />
            <CardTail>
              <Paging totalCount={galleries?.totalElements} currentPage={searchCondition.page} size={galleries?.size} handleChangePage={handleChangePage} />
            </CardTail>
          </S.CardInner>
        </S.CardCol>
      )}
    </S.CardRow>
  );
};

export default CliniCalGallery;
