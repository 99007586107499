import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getLoadExcel, useGetStudyList } from "../apis/studyApi";
import BoardTotal from "../components/board/BoardTotal";
import { CardHead, CardTail } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import StudyFilter from "../components/study/StudyFilter";
import StudyTableTr from "../components/study/StudyTableTr";
import StudyTabs from "../components/study/StudyTabs";
import * as S2 from "../elements/Button/style/ButtonLayout.style";
import DataTable from "../elements/DataTable";
import DataTableSkeleton from "../elements/DataTableSkeleton";
import Paging from "../elements/Paging";
import { usePermissionCheck } from "../hooks/usePermissionCheck";
import { useSearch } from "../hooks/useSearch";
import { authStudyTableCustom } from "../utils/centerStatus";
import { IK } from "../utils/i18n/keyStore";
import { changeParam, urlParamToObject } from "../utils/searchParams";

const StudyList = () => {
  const { t } = useTranslation(["translation"]);

  /**권한처리 */
  const studyAuth = usePermissionCheck("studyList");

  /**검색 조건 설정 */
  const [searchCondition, setSearchCondition] = useState(window.location.search ? urlParamToObject() : { page: 1, detail: "total" });
  /**검색 조건 url에 추가 */
  useSearch(searchCondition);
  const { data: studyData, isLoading } = useGetStudyList(changeParam(searchCondition));
  const studys = studyData?.data;

  /**페이지 설정 */
  const handleChangePage = (e) => {
    setSearchCondition((prev) => ({ ...prev, page: e }));
  };

  /**엑셀 다운로드 */
  const handleExcel = () => {
    const langInfo = localStorage.getItem("langInfo");
    getLoadExcel(changeParam({ ...searchCondition, page: 1 }), t, langInfo);
  };

  /**탭 필터 */
  const [activeTab, setActiveTab] = useState(searchCondition?.detail || "total");

  return (
    <>
      {/* <button
        onClick={() => {
          axios.get("/api/admin/admin-login?doctorId=7").then((res) => {
            window.open(res.data, "_blank");
          });
        }}
      >
        테스트용의사로그인
      </button> */}
      <S.CardRow>
        <S.CardCol>
          <S.CardInner>
            <StudyTabs searchCondition={searchCondition} setSearchCondition={setSearchCondition} setActiveTab={setActiveTab} activeTab={activeTab} />
            <StudyFilter searchCondition={searchCondition} setSearchCondition={setSearchCondition} setActiveTab={setActiveTab} />
          </S.CardInner>
        </S.CardCol>
        {isLoading ? (
          <DataTableSkeleton tableCol={authStudyTableCustom(studyAuth, t).col} tableHead={authStudyTableCustom(studyAuth, t).head} />
        ) : (
          <S.CardCol>
            <S.CardInner>
              <CardHead>
                <BoardTotal total={studys?.totalElements} />
                <S2.StyledButton $excel type="submit" onClick={handleExcel} as="button">
                  <i className="ri-file-excel-2-line" />
                  {t(IK.download_treatment_list)}
                </S2.StyledButton>
              </CardHead>
              <DataTable
                BoardTableType2
                tableCol={authStudyTableCustom(studyAuth, t).col}
                tableHead={authStudyTableCustom(studyAuth, t).head}
                dataLength={studys?.totalElements}
                tableTr={<StudyTableTr studys={studys?.content} totalElements={studys?.totalElements} page={searchCondition.page} studyAuth={studyAuth} />}
              />
              <CardTail>
                <Paging totalCount={studys?.totalElements} currentPage={searchCondition.page} size={studys?.size} handleChangePage={handleChangePage} />
              </CardTail>
            </S.CardInner>
          </S.CardCol>
        )}
      </S.CardRow>
    </>
  );
};

export default StudyList;
