import { yupResolver } from "@hookform/resolvers/yup";
import Notiflix from "notiflix";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { checkLoginId } from "../apis/accountApi";
import { useAddCenterInfo } from "../apis/centerApi";
import { CardTail, CardTitle } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import * as S3 from "../elements/Button/style/ButtonLayout.style";
import { FormControlsBox } from "../elements/Form/FormLayout";
import * as S2 from "../elements/Form/style/Form.style";
import { ErrorMessage, SuccessMessage } from "../elements/Message/MessageText";
import { permissions } from "../utils/centerStatus";
import { enterKeyDown } from "../utils/enterKeydown";
import { IK } from "../utils/i18n/keyStore";
import { countryLabel } from "../utils/searchLabel";

const CenterAdd = () => {
  const { t } = useTranslation(["translation"]);
  const [checkId, setCheckId] = useState(false);
  const navigate = useNavigate();

  /**새로운 센터 계정 추가 */
  const addCenterMutation = useAddCenterInfo();

  const formSchema = Yup.object({
    name: Yup.string().min(2, t(IK.name_min_validate)).required(t(IK.name_required)),
    loginId: Yup.string()
      .required(t(IK.id_required))
      .matches(/^[A-Za-z0-9]+$/, t(IK.id_validate))
      .min(4, t(IK.id_min_validate)),
    loginIdCheck: Yup.bool().isTrue().oneOf([true], t(IK.id_duplicate_check_required)),
    loginPw: Yup.string()
      .required(t(IK.password_required))
      .min(8, t(IK.password_min_validate))
      .max(16, t(IK.password_max_validate))
      .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[^\s]*$/, t(IK.password_pattern_validate)),
    reLoginPw: Yup.string()
      .required(t(IK.password_check_required))
      .oneOf([Yup.ref("loginPw"), null], t(IK.password_not_match)),
    roleGroupCode: Yup.string().required(t(IK.role_required)),
    countryCode: Yup.string().required(t(IK.country_required)),
  });

  /**중복검사 초기값 설정 */
  const defaultValues = {
    loginIdCheck: false,
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setValue,
    setError,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  /**아이디 검사 */
  const dupulicateId = (e) => {
    const loginId = getValues("loginId");
    if (loginId) {
      checkLoginId({ loginId })
        .then((res) => {
          if (res.data.code === 200) {
            setCheckId(true);
            setValue("loginIdCheck", true);
          }
        })
        .catch((e) => {
          setCheckId(false);
          setError("loginIdCheck", {
            type: "manual",
            message: t(IK.duplicate_id),
          });
        });
    }
  };

  /**중복검사 후 다시 기입할 경우 감지 */
  const handleKeyDown = (event) => {
    setCheckId(false);
    setValue("loginIdCheck", false);
  };

  /**계정 등록 제출*/
  const onRegisterCenter = (data) => {
    addCenterMutation.mutate(data, {
      onSuccess: () => {
        navigate("/centerList");
        Notiflix.Report.success(t(IK.add_success), "", t(IK.confirm), () => {});
      },
      onError: () => {
        Notiflix.Report.failure(t(IK.add_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    });
  };

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardInner>
          <CardTitle title={t(IK.center_register)} txt />
          <form onSubmit={handleSubmit(onRegisterCenter)} onKeyDown={enterKeyDown}>
            <S2.FormControlsContainer>
              <FormControlsBox required title={t(IK.name)}>
                <S2.FormControls type="text" {...register("name")} />
                <ErrorMessage errors={errors} name="name" />
              </FormControlsBox>

              <FormControlsBox required title={t(IK.id)}>
                <S2.FormControlsFlex>
                  <S2.FormControls type="text" {...register("loginId")} onKeyDown={handleKeyDown} />
                  <S2.FormControlsBtn type="button" onClick={dupulicateId}>
                    {t(IK.duplicate_btn)}
                  </S2.FormControlsBtn>
                </S2.FormControlsFlex>
                <ErrorMessage errors={errors} name="loginId" />
                {checkId ? <SuccessMessage text={t(IK.available_id_message)} /> : <ErrorMessage errors={errors} name="loginIdCheck" />}
              </FormControlsBox>

              <FormControlsBox required title={t(IK.password)}>
                <S2.FormControls type="password" {...register("loginPw")} autoComplete="new-password" />
                <ErrorMessage errors={errors} name="loginPw" />
              </FormControlsBox>

              <FormControlsBox required title={t(IK.password_check)}>
                <S2.FormControls type="password" {...register("reLoginPw")} />
                <ErrorMessage errors={errors} name="reLoginPw" />
              </FormControlsBox>

              <FormControlsBox required title={t(IK.center_permissions)}>
                <S2.FormControls {...register("roleGroupCode")} as="select" $select>
                  {permissions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {t(option.text)}
                    </option>
                  ))}
                </S2.FormControls>
                <ErrorMessage errors={errors} name="roleGroupCode" />
              </FormControlsBox>

              <FormControlsBox required title={t(IK.country)}>
                <S2.FormControls {...register("countryCode")} as="select" $select>
                  {countryLabel.map((option) => (
                    <option key={option.value} value={option.value}>
                      {t(option.label)}
                    </option>
                  ))}
                </S2.FormControls>
                <ErrorMessage errors={errors} name="countryCode" />
              </FormControlsBox>
            </S2.FormControlsContainer>

            <CardTail line>
              <S3.ButtonLtBox>
                <S3.StyledButton to="/centerList">{t(IK.cancel)}</S3.StyledButton>
                <S3.StyledButton $primary as="button">
                  {t(IK.registration)}
                </S3.StyledButton>
              </S3.ButtonLtBox>
            </CardTail>
          </form>
        </S.CardInner>
      </S.CardCol>
    </S.CardRow>
  );
};

export default CenterAdd;
