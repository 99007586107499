import Notiflix from "notiflix";
import React, { useCallback, useState } from "react";
import { useGetDoctorSearch } from "../../apis/doctorApi";
import { useTransferAllPatient, useTransferPatient } from "../../apis/patientApi";
import * as S from "../../components/layout/CardLayout/style/CardLayout.style";
import * as S2 from "../../elements/Button/style/ButtonLayout.style";
import * as S4 from "../../elements/Form/style/Form.style";
import * as S3 from "../../elements/Table/style/Table.style";
import { useKeyword } from "../../hooks/useKeyword";

const PatientTransferFilter = ({ setTransfers, transfers }) => {
  const [searchList, setSearchList] = useState({
    from: { list: true, name: "", doctorData: {} },
    to: { list: true, name: "", doctorData: {} },
  });

  // 검색어는 그대로 입력되고, API 호출용 값은 debounce 처리
  const fromSearch = useKeyword(searchList.from.name, 300);
  const toSearch = useKeyword(searchList.to.name, 300);

  const { data: fromData } = useGetDoctorSearch(fromSearch);
  const { data: toData } = useGetDoctorSearch(toSearch);

  const handleInputChange = (field) => (e) => {
    setSearchList((prev) => ({
      ...prev,
      [field]: { ...prev[field], list: true, name: e.target.value, doctorData: {} },
    }));
  };

  const handleDoctorSelection = useCallback(
    (field, doctor) => {
      setTransfers((prev) => ({
        ...prev,
        [`${field}DoctorId`]: doctor.doctorId,
        patientIds: [],
      }));
      setSearchList((prev) => ({
        ...prev,
        [field]: {
          list: false,
          name: doctor.doctorName,
          doctorData: { ...doctor },
        },
      }));
    },
    [setTransfers]
  );

  const eachTransferMutation = useTransferPatient();
  /**선택 이관 */
  const handleTransfer = useCallback(() => {
    if (transfers.fromDoctorId === "" || transfers.toDoctorId === "") {
      return Notiflix.Report.warning("이관 실패", "이관할 의사를 선택해주세요.", "확인");
    }

    if (transfers.patientIds.length === 0) {
      return Notiflix.Report.warning("이관 실패", "이관할 환자를 선택해주세요.", "확인");
    }

    //이관 진행
    eachTransferMutation.mutate(
      {
        patientIds: transfers.patientIds,
        toDoctorId: transfers.toDoctorId,
      },
      {
        onSuccess: () => {
          setTransfers((prev) => ({
            ...prev,
            patientIds: [],
          }));
        },
      }
    );
  }, [transfers, eachTransferMutation, setTransfers]);

  const allTransferMutation = useTransferAllPatient();

  /**전체 이관 */
  const handleAllTransfer = useCallback(() => {
    if (transfers.fromDoctorId === "" || transfers.toDoctorId === "") {
      return Notiflix.Report.warning("이관 실패", "이관할 의사를 선택해주세요.", "확인");
    }

    //이관 진행
    allTransferMutation.mutate(
      {
        fromDoctorId: transfers.fromDoctorId,
        toDoctorId: transfers.toDoctorId,
      },
      {
        onSuccess: () => {
          setTransfers((prev) => ({
            ...prev,
            patientIds: [],
          }));
        },
      }
    );
  }, [transfers, allTransferMutation, setTransfers]);

  const renderDoctorList = (field, data) => (
    <S4.FormSelectListBox>
      <S4.FormControls placeholder="의사 선택" onChange={handleInputChange(field)} value={searchList[field].name} />
      {searchList[field].list && data?.length > 0 && (
        <S4.FormSelectList>
          {data.map((suggestion, index) => (
            <li key={index} onClick={() => handleDoctorSelection(field, suggestion)}>
              <p>
                {suggestion.loginId}, {suggestion.addressName}
              </p>
              <p>
                {suggestion.doctorName}
                {suggestion.doctorKoreanName && ` (${suggestion.doctorKoreanName})`}
              </p>
            </li>
          ))}
        </S4.FormSelectList>
      )}
    </S4.FormSelectListBox>
  );

  const renderDoctorInfo = (doctorData) => {
    if (!doctorData || Object.keys(doctorData).length === 0) {
      return ""; // 빈 객체일 경우 표시할 기본 값
    }

    return `${doctorData.doctorName}${doctorData.doctorKoreanName ? ` (${doctorData.doctorKoreanName})` : ""} : ${doctorData.loginId}`;
  };

  return (
    <S.CardInner>
      <S3.TableType2>
        <colgroup>
          <col width="200px" />
          <col width="*" />
          <col width="200px" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr>
            <th>from 의사</th>
            <td>{renderDoctorList("from", fromData)}</td>
            <th>to 의사</th>
            <td>{renderDoctorList("to", toData)}</td>
          </tr>
          <tr>
            <th>from 의사 정보</th>
            <td>{renderDoctorInfo(searchList.from.doctorData)}</td>
            <th>to 의사 정보</th>
            <td>{renderDoctorInfo(searchList.to.doctorData)}</td>
          </tr>
        </tbody>
      </S3.TableType2>
      <S2.ButtonCtBox>
        <S2.StyledButton $primary as="button" onClick={handleTransfer}>
          선택 이관
        </S2.StyledButton>
        <S2.StyledButton as="button" onClick={handleAllTransfer}>
          전체 이관
        </S2.StyledButton>
      </S2.ButtonCtBox>
    </S.CardInner>
  );
};

export default PatientTransferFilter;
