import { useTranslation } from "react-i18next";
import * as S from "../../elements/Tab/style/Tab.style";
import { IK } from "../../utils/i18n/keyStore";

const ClinicalTab = ({ searchCondition, setSearchCondition }) => {
  const { t } = useTranslation(["translation"]);

  const tabList = [
    { name: t(IK.total), copy: undefined },
    { name: t(IK.submitted), copy: "false" },
    { name: t(IK.copy), copy: "true" },
  ];

  const handleOnClickTab = (copy) => {
    setSearchCondition((prev) => ({ ...prev, page: 1, copy }));
  };

  return (
    <S.TabType2>
      {tabList.map((tab) => (
        <S.TabType2Item $active={searchCondition?.copy === tab.copy} key={tab.name} onClick={() => handleOnClickTab(tab.copy)}>
          <button type="button">{tab.name}</button>
        </S.TabType2Item>
      ))}
    </S.TabType2>
  );
};

export default ClinicalTab;
