import { IK } from "./i18n/keyStore";

/**대륙별 국가 분리 */
export const continentsData = [
  {
    name: "asia",
    countries: ["korea", "japan", "china", "vietnam", "india", "myanmar", "mongolia", "thailand", "taiwan", "cambodia", "philippines", "hongkong", "malaysia", "singapore", "kazakhstan"],
  },
  {
    name: "europe",
    countries: ["france", "uk", "germany", "italy", "russia"],
  },
  {
    name: "america",
    countries: ["usa", "canada", "brazil", "mexico"],
  },
  {
    name: "oceania",
    countries: ["australia", "newzealand"],
  },
  {
    name: "africa",
    countries: ["egypt"],
  },
];

/**
 * 국가별 국가코드
 * @type {Record<국가코드, 국가언어팩키>}
 */

export const countryObject = {
  "01": "korea",
  "02": "japan",
  "03": "china",
  "04": "usa",
  "05": "mongolia",
  "06": "taiwan",
  "07": "thailand",
  "08": "vietnam",
  "09": "australia",
  10: "newzealand",
  11: "uk",
  12: "france",
  13: "italy",
  14: "germany",
  15: "egypt",
  16: "brazil",
  17: "mexico",
  18: "canada",
  19: "russia",
  20: "india",
  21: "myanmar",
  22: "cambodia",
  23: "philippines",
  24: "hongkong",
  25: "malaysia",
  26: "singapore",
  27: "kazakhstan",
};

/**
 * 국가명에 따른 국가 코드 반환
 * @param {string} country - 국가명
 * @returns {string} 국가 코드
 */
export const countryToCode = (country) => {
  const codeByCountry = new Map(Object.entries(countryObject).map(([code, country]) => [country, code]));

  const code = codeByCountry.get(country);

  if (!code) {
    //국가코드 오류 있을 경우 에러 발생
    throw new Error(`국가 코드를 찾을 수 없습니다: ${country}`);
  }

  return code;
};

/**
 * 국가코드를 국가명으로 변환
 * @param {string} code - 국가 코드
 * @returns {string} 국가명
 */
export const codeToCountry = (code) => {
  return countryObject[code];
};

/**
 * 국가코드 문자열을 국가명 배열로 변환
 * @param {string} codes - 국가 코드 문자열
 * @returns {string[]} 국가명 배열
 */
export const codeToCountryArray = (codes) => codes.map((code) => codeToCountry(code));

/**
 * 국가명 배열을 국가(언어팩 적용) 문자열로 변환
 */

export const countryArrayToString = (countries, t) => {
  return countries.map((country) => t(IK[country])).join(", ");
};

/** 국가 코드 재정렬
 * @description 검색어에 해당하는 국가 코드를 앞으로 이동
 * @param {string[]} codes - 국가 코드 배열
 * @param {string[]} searchTerms - 검색된 국가 코드 배열
 */
export const reorderCountryCodes = (codes, searchTerms) => {
  if (!searchTerms || searchTerms?.length === 0) return codes;

  // 검색어에 해당하는 코드를 찾아 앞으로 옮김
  const matchedCodes = codes.filter((code) => searchTerms.includes(code)).sort((a, b) => searchTerms.indexOf(a) - searchTerms.indexOf(b));
  // 검색어에 해당하지 않는 나머지 코드
  const remainingCodes = codes.filter((code) => !searchTerms.includes(code));

  return [...matchedCodes, ...remainingCodes];
};
