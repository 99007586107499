import loadImage from "blueimp-load-image";

/**드래그앤드랍 이미지 회전 */
export default async function imageRotate(file, setFiles, orientation, setFieldValue, name) {
  try {
    const data = await loadImage(file[0], { meta: false, canvas: true, orientation: orientation });
    const blob = await new Promise((resolve) => data.image.toBlob(resolve));

    const rotateFile = new File([blob], file[0].name, {
      type: `image/${file[0].name.split(".").pop()}`,
      lastModified: Date.now(),
    });

    rotateFile.preview = URL.createObjectURL(rotateFile);

    setFiles([rotateFile]);
    setFieldValue && setFieldValue(name, rotateFile);
  } catch (error) {
    console.error("이미지 회전 실패:", error);
  }
}

export const rotateImage = async (file, orientation) => {
  try {
    const data = await loadImage(file, { meta: false, canvas: true, orientation: orientation });
    const blob = await new Promise((resolve) => data.image.toBlob(resolve));

    const rotateFile = new File([blob], file.name, {
      type: `image/${file.name.split(".").pop()}`,
      lastModified: Date.now(),
    });

    //회전된 이미지 preview 값 추가
    const rotateImg = {
      file: rotateFile,
      preview: URL.createObjectURL(rotateFile),
    };

    return rotateImg;
  } catch (error) {
    console.error("이미지 회전 실패:", error);
  }
};
