//스타일
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import * as S3 from "../elements/Button/style/ButtonLayout.style";
import * as S2 from "../elements/Form/style/Form.style";
import * as S4 from "../elements/SelectionControls/style/SelectionControls.style";

import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import Notiflix from "notiflix";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useAddEvent, useDeleteEvent, useEditEvent, useGetEventInfo } from "../apis/eventApi";
import BoardPostSkeleton from "../components/board/BoardPostSkeleton";
import CountryBtn from "../components/country/CountryBtn";
import SelectedCountryList from "../components/country/SelectedCountryList";
import { CardTail, CardTitle } from "../components/layout/CardLayout/CardLayout";
import { TimePicker } from "../elements/DatePicker";
import { FormControlsBox } from "../elements/Form/FormLayout";
import { ErrorMessage, NotiMessage } from "../elements/Message/MessageText";
import TextEditor from "../elements/TextEditor";
import { usePermissionCheck } from "../hooks/usePermissionCheck";
import { IK } from "../utils/i18n/keyStore";
import { onDateChange } from "../utils/searchParams";

const EventBoardPost = () => {
  /**게시판 아이디 */
  const { eventBoardId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(["translation"]);

  /**권한 조회 */
  const eventAuth = usePermissionCheck("eventList");

  /**이벤트 게시판 상세조회
   * 수정시에만 조회
   */
  const { data, isLoading } = useGetEventInfo(eventBoardId);
  const editBoardInfo = data?.data;

  /**유효성 검사 */
  const formSchema = Yup.object({
    title: Yup.string().required(t(IK.event_title_required)),
    countryCodes: Yup.array().when("$countryCode", {
      is: () => eventAuth?.isAll,
      then: () => Yup.array().required(t(IK.country_required)).min(1, t(IK.country_required)),
      otherwise: () => Yup.array().notRequired(),
    }),
    startDate: Yup.string().required(t(IK.event_start_date_required)),
    endDate: Yup.string().required(t(IK.event_end_date_required)),
    bannerShowYn: Yup.string().required(t(IK.event_vip_show_required)),
    bannerSortOrder: Yup.string().when("bannerShowYn", {
      is: (val) => val === "Y",
      then: () => Yup.string().matches(/^\d*$/, t(IK.only_number)).required(t(IK.event_banner_order_required)),
    }),
    banner: Yup.mixed().when("bannerShowYn", {
      is: (val) => val === "Y",
      then: () => Yup.mixed().required(t(IK.event_banner_image_required)),
    }),
    thumb: Yup.mixed().required(t(IK.board_thumb_required)),
  });

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const boardWatch = watch();

  /**초기값 셋팅 */
  useEffect(() => {
    if (!isLoading && editBoardInfo) {
      const { startDate, endDate, bannerFile, eventBoardFile, countryCodes } = editBoardInfo;
      setValue("startDate", dayjs(startDate).format("YYYY-MM-DD HH:mm:ss"));
      setValue("endDate", dayjs(endDate).format("YYYY-MM-DD HH:mm:ss"));
      setValue("bannerShowYn", bannerFile[0]?.showYn || "N");
      setValue("banner", bannerFile[0]);
      setValue("thumb", eventBoardFile[0]);
      setValue("content", editBoardInfo.content);
      if (countryCodes?.length > 0) setValue("countryCodes", countryCodes);
    }
  }, [isLoading, editBoardInfo, setValue]);

  const addEventMutation = useAddEvent();
  const editEventMutation = useEditEvent();

  /**이벤트 게시물 등록 */
  const submitContent = (data) => {
    //종료일이 시작일 보다 빠른 경우 경고
    if (data.startDate > data.endDate) {
      return Notiflix.Report.failure(t(IK.event_date_invaild), t(IK.again_message), t(IK.confirm), () => {});
    }

    const formData = new FormData();

    //파일 아이디 가져오기
    const bannerFileId = editBoardInfo?.bannerFile[0]?.bannerFileId;
    const eventBoardFileId = editBoardInfo?.eventBoardFile[0]?.eventBoardFileId;

    //배너 반영 아니오 선택시 데이터 삭제
    if (data.bannerShowYn === "N") {
      delete data.bannerSortOrder;
      delete data.banner;
      if (bannerFileId) formData.append("deleteBannerId", bannerFileId);
    }

    //데이터 셋팅
    Object.entries(data).forEach(([key, value]) => {
      //빈값이 아닌 경우
      if (value && !(Array.isArray(value) && value.length === 0)) {
        //이미 파일로 등록된 경우 제외
        if (!((key === "banner" || key === "thumb") && value.fileId)) {
          formData.append(key, value);
        }
      }
    });

    //수정인 경우
    if (eventBoardId) {
      //새롭게 등록(name이 있음) && 기존 파일 아이디를 가진 경우 삭제
      if (boardWatch?.banner?.name && !!bannerFileId) {
        formData.append("deleteBannerId", bannerFileId);
      }
      if (boardWatch?.thumb?.name && !!eventBoardFileId) {
        formData.append("deleteBoardFileId", eventBoardFileId);
      }
      editEventMutation.mutate({ eventBoardId, data: formData });
    } else {
      addEventMutation.mutate(formData);
    }
  };

  const deleteEventMutation = useDeleteEvent();
  /**게시물 삭제 */
  const handleDelete = () => {
    Notiflix.Confirm.show(t(IK.warning_delete_1), t(IK.warning_delete_2), t(IK.confirm), t(IK.cancel), () => deleteEventMutation.mutate(eventBoardId));
  };

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardInner>
          <CardTitle title={t(IK.event)} txt />
          {isLoading ? (
            <BoardPostSkeleton />
          ) : (
            <form onSubmit={handleSubmit(submitContent)}>
              <S2.FormControlsContainer>
                {/* 국가 선택 */}
                {eventAuth?.isAll && (
                  <>
                    <FormControlsBox required title={t(IK.country)}>
                      <CountryBtn setValue={setValue} clearErrors={clearErrors} watch={boardWatch?.countryCodes} />
                      <ErrorMessage errors={errors} name="countryCodes" />
                    </FormControlsBox>
                    {boardWatch?.countryCodes?.length > 0 && (
                      <FormControlsBox required title={`${t(IK.country)} ${t(IK.list)}`}>
                        <SelectedCountryList watch={boardWatch.countryCodes} setValue={setValue} t={t} />
                      </FormControlsBox>
                    )}
                  </>
                )}
                {/* 게시판 제목 */}
                <FormControlsBox required title={t(IK.board_title)}>
                  <S2.FormControls {...register("title")} defaultValue={editBoardInfo?.title || ""} disabled={!eventAuth?.isAll} />
                  <ErrorMessage errors={errors} name="title" />
                </FormControlsBox>
                {/* 게시판 내용 */}
                <FormControlsBox title={t(IK.board_content)}>
                  <TextEditor setData={(content) => setValue("content", content)} content={editBoardInfo?.content} disabled={!eventAuth?.isAll} />
                </FormControlsBox>
                {/* 진행 기간 */}
                <FormControlsBox required title={t(IK.event_date)}>
                  <S2.FormControlsFlex>
                    <div>
                      <TimePicker
                        disabled={!eventAuth?.isAll}
                        handleDataChange={(date) => onDateChange(date, setValue, "startDate")}
                        placeholderText={t(IK.create_date)}
                        selected={(boardWatch.startDate && new Date(boardWatch.startDate)) || (editBoardInfo?.startDate && new Date(editBoardInfo.startDate))}
                        formTimeControls
                      />
                      {!boardWatch.startDate && <ErrorMessage errors={errors} name="startDate" />}
                    </div>

                    <div>
                      <TimePicker
                        disabled={!eventAuth?.isAll}
                        handleDataChange={(date) => onDateChange(date, setValue, "endDate")}
                        placeholderText={t(IK.event_end_date)}
                        selected={(boardWatch.endDate && new Date(boardWatch.endDate)) || (editBoardInfo?.endDate && new Date(editBoardInfo.endDate))}
                        formTimeControls
                      />
                      {!boardWatch.endDate && <ErrorMessage errors={errors} name="endDate" />}
                    </div>
                  </S2.FormControlsFlex>
                </FormControlsBox>
                {/* 배너 반영 여부 */}
                <FormControlsBox required title={t(IK.event_vip_show)}>
                  <S4.SelectionItemList>
                    <S4.SelectionItem>
                      <S4.SelectionItemLabel>
                        <S4.SelectionItemInput $radio type="radio" id="showYn_Y" {...register("bannerShowYn")} value="Y" disabled={!eventAuth?.isAll} />
                        <S4.SelectionItemSpan htmlFor="showYn_Y">{t(IK.yes)}</S4.SelectionItemSpan>
                      </S4.SelectionItemLabel>
                    </S4.SelectionItem>
                    <S4.SelectionItem>
                      <S4.SelectionItemLabel>
                        <S4.SelectionItemInput $radio type="radio" id="showYn_N" {...register("bannerShowYn")} value="N" disabled={!eventAuth?.isAll} />
                        <S4.SelectionItemSpan htmlFor="showYn_N">{t(IK.no)}</S4.SelectionItemSpan>
                      </S4.SelectionItemLabel>
                    </S4.SelectionItem>
                  </S4.SelectionItemList>
                  <ErrorMessage errors={errors} name="bannerShowYn" />
                </FormControlsBox>

                {/** 배너 순서, 이미지
                 * 배너 반영하는 경우에만 순서 지정, 배너 이미지 추가 가능*/}
                {boardWatch.bannerShowYn === "Y" && (
                  <>
                    <FormControlsBox title={t(IK.event_vip_show_order)} required>
                      <S2.FormControls $small {...register("bannerSortOrder")} defaultValue={editBoardInfo?.bannerFile[0]?.sortOrder || ""} placeholder="ex) 1" disabled={!eventAuth?.isAll} />
                      <ErrorMessage errors={errors} name="bannerSortOrder" />
                    </FormControlsBox>

                    <FormControlsBox title={t(IK.event_banner)} required>
                      <S2.FormControlsFlex>
                        <S2.FormControls disabled value={boardWatch.banner?.name || editBoardInfo?.bannerFile[0]?.orgFileName || ""} />
                        <S2.FormControls type="file" id="banner" name="banner" accept="image/*" onChange={(event) => setValue("banner", event.target.files[0])} hidden disabled={!eventAuth?.isAll} />
                        <S2.FormControlsBtn as="label" htmlFor="banner">
                          {t(IK.file_btn)}
                        </S2.FormControlsBtn>
                      </S2.FormControlsFlex>
                      {!boardWatch.banner && <ErrorMessage errors={errors} name="banner" />}
                      <NotiMessage text={`${t(IK.recommended_size)} 196 x 100`} />
                    </FormControlsBox>
                  </>
                )}

                {/* 썸네일 */}
                <FormControlsBox required title={t(IK.board_thumb)}>
                  <S2.FormControlsFlex>
                    <S2.FormControls disabled value={boardWatch.thumb?.name || editBoardInfo?.eventBoardFile[0]?.orgFileName || ""} />
                    <S2.FormControls type="file" id="thumb" name="thumb" accept="image/*" onChange={(event) => setValue("thumb", event.target.files[0])} hidden disabled={!eventAuth?.isAll} />
                    <S2.FormControlsBtn as="label" htmlFor="thumb">
                      {t(IK.file_btn)}
                    </S2.FormControlsBtn>
                  </S2.FormControlsFlex>
                  {!boardWatch.thumb && <ErrorMessage errors={errors} name="thumb" />}
                  <NotiMessage text={`${t(IK.recommended_size)} 288 x 179`} />
                </FormControlsBox>
              </S2.FormControlsContainer>

              <CardTail line>
                <S3.ButtonLtBox>
                  {eventBoardId ? (
                    <>
                      <S3.StyledButton as="button" type="button" onClick={() => navigate(`/support/event${location.state?.herf || `?page=1`}`)}>
                        {t(IK.list)}
                      </S3.StyledButton>
                      {/**국가코드가 전체인 경우만 수정, 삭제 가능 */}
                      {eventAuth?.isAll && (
                        <>
                          <S3.StyledButton as="button" $secondary type="button" onClick={handleDelete}>
                            {t(IK.delete)}
                          </S3.StyledButton>
                          <S3.StyledButton as="button" $primary type="submit">
                            {t(IK.modify)}
                          </S3.StyledButton>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <S3.StyledButton as="button" type="button" onClick={() => navigate(`/support/event${location.state?.herf || `?page=1`}`)}>
                        {t(IK.cancel)}
                      </S3.StyledButton>
                      <S3.StyledButton as="button" type="submit" $primary>
                        {t(IK.registration)}
                      </S3.StyledButton>
                    </>
                  )}
                </S3.ButtonLtBox>
              </CardTail>
            </form>
          )}
        </S.CardInner>
      </S.CardCol>
    </S.CardRow>
  );
};

export default EventBoardPost;
