import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { getUserPermissionCheck } from "../../../apis/accountApi";
import { userAuth, userTimezone } from "../../../atom/userAtom";
import { isPermissionRead } from "../../../utils/centerStatus";
import { IK } from "../../../utils/i18n/keyStore";
import AlarmPopup from "../../alarm/AlarmPopup";
import SideHead from "./SideHead";
import SideNavi from "./SideNavi";
import SideTail from "./SideTail";
import * as S from "./style/SideBar.style";

const SideBar = () => {
  const { t } = useTranslation(["translation"]);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  /**사용자 정보 가져오기 */
  const [user, setUser] = useRecoilState(userAuth);

  /**타임존 설정 */
  const setSelectedTimezone = useSetRecoilState(userTimezone);

  /**유저정보 조회 후 저장*/
  useEffect(() => {
    getUserPermissionCheck().then((res) => {
      const response = res?.data?.data;
      setUser(response);
      setSelectedTimezone(response?.accountTimeZone?.split(":")[0]);
    });
  }, [setUser, setSelectedTimezone]);

  /**권한 체크 */
  const auth = isPermissionRead(user.roleNames, pathname);

  /**권한 처리 */
  useEffect(() => {
    if (!auth) {
      return navigate("/studyList");
    }
  }, [auth, navigate]);

  /**알림 팝업 */
  const [alarmPopup, setAlarmPopup] = useState(false);

  /**알림 팝업 ref */
  const alarmModalRef = useRef();

  // 외부 클릭 시 모달 닫기
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (alarmModalRef?.current && !alarmModalRef?.current.contains(event.target)) {
        setAlarmPopup(false);
      }
    };

    // 모달 외부 클릭 감지 이벤트 리스너 등록
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const naviList = useMemo(() => {
    const filteredNaviList = [
      //{ title: t(IK.dashboard), nav: "/", icon: "ri-home-line" },
      { title: t(IK.study), nav: "/studyList", icon: "ri-ball-pen-fill" },
      { title: t(IK.patient), nav: "/patientList", icon: "ri-user-heart-fill" },
      { title: t(IK.doctor), nav: "/doctorList", icon: "ri-stethoscope-fill" },
      { title: t(IK.center), nav: "/centerList", icon: "ri-hospital-fill" },
      { title: t(IK.coupon), nav: "/couponList", icon: "ri-coupon-3-fill" },
      { title: t(IK.billing), nav: "/billList", icon: "ri-refund-2-fill" },
      {
        title: t(IK.education),
        nav: "/education",
        icon: "ri-health-book-fill",
        depth: [
          { title: t(IK.do_better), nav: "/education/do-better" },
          { title: t(IK.ebook), nav: "/education/eBook" },
          { title: t(IK.clinical_gallery), nav: "/education/clinical-gallery" },
          { title: t(IK.resources), nav: "/education/resources" },
          //최고관리자는 교육영상 메뉴 추가
          ...(user?.roleNames?.includes("SUPER_ADMIN") ? [{ title: t(IK.support_link), nav: "/education/video" }] : []),
        ],
      },
      {
        title: t(IK.support),
        nav: "/support",
        icon: "ri-dossier-fill",
        depth: [
          { title: t(IK.seraview_file), nav: "/support/seraviewFile" },
          { title: t(IK.seraquick_file), nav: "/support/seraquickFile" },
          { title: t(IK.delivery_key), nav: "/support/deliverykey" },
          { title: t(IK.event), nav: "/support/event" },
          { title: t(IK.notice_board), nav: "/support/notice" },
          { title: t(IK.pop_up), nav: "/support/popup" },
        ],
      },
    ].filter((navi) => isPermissionRead(user.roleNames, navi.nav));

    return filteredNaviList;
  }, [t, user.roleNames]);

  return (
    <>
      <S.SideBarContainer>
        <SideHead />
        <S.SideBarNav>
          <S.SideBarNavDepth1>
            {naviList.map((navi) => (
              <SideNavi key={navi.nav} navi={navi} />
            ))}
          </S.SideBarNavDepth1>
        </S.SideBarNav>
        <SideTail setAlarmPopup={setAlarmPopup} />
      </S.SideBarContainer>
      {alarmPopup && (
        <div ref={alarmModalRef}>
          <AlarmPopup setAlarmPopup={setAlarmPopup} />
        </div>
      )}
    </>
  );
};

export default SideBar;
