import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useGetBoardList } from "../../apis/boardApi";
import { EmptyCard } from "../../elements/EmptyComponent/EmptyCard";
import Paging from "../../elements/Paging";
import { useSearchParam } from "../../hooks/useSearchParam";
import { IK } from "../../utils/i18n/keyStore";
import { changeBoradParam } from "../../utils/searchParams";
import CountryBadge from "../country/CountryBadge";
import { CardHead, CardTail } from "../layout/CardLayout/CardLayout";
import BoardSkeleton from "./BoardSkeleton";
import BoardTotal from "./BoardTotal";

//스타일 관련
import * as S5 from "../../components/board/style/BoardList.style";
import * as S from "../../components/layout/CardLayout/style/CardLayout.style";
import * as S4 from "../../elements/Button/style/ButtonLayout.style";
import { usePermissionCheck } from "../../hooks/usePermissionCheck";
import BoardFilter from "./BoardFilter";

const BoardList = ({ boardType, boardLink }) => {
  const { t } = useTranslation(["translation"]);

  /**권한 체크 */
  const boardAuth = usePermissionCheck("boardList");

  /**검색 조건 설정*/
  const { searchCondition, setSearchCondition } = useSearchParam({ page: 1, boardSort: boardType });

  /**페이지 사이즈(고정) */
  const searchSize = 10;

  const { data, isLoading } = useGetBoardList(changeBoradParam(searchCondition));
  const boards = data?.data;

  /**페이지 설정 */
  const handleChangePage = (page) => {
    setSearchCondition((prev) => ({ ...prev, page }));
  };

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardInner>
          {/* 검색 필터 */}
          <BoardFilter searchCondition={searchCondition} setSearchCondition={setSearchCondition} t={t} isAllCountry={boardAuth?.isAll} boardType={boardType} />
        </S.CardInner>
      </S.CardCol>

      <S.CardCol>
        <S.CardInner>
          {isLoading ? (
            <BoardSkeleton />
          ) : (
            <>
              {/**헤더 */}
              <CardHead line>
                <BoardTotal total={boards?.totalElements} />
                {boardAuth?.isAll && (
                  <S4.StyledButton $primary to={`${boardLink}/post`}>
                    {t(IK.registration)}
                  </S4.StyledButton>
                )}
              </CardHead>
              {/**카드 */}
              {boards?.totalElements > 0 ? (
                <S5.BoardCardList>
                  {boards?.content?.map((content) => (
                    <li key={content.boardId}>
                      <Link to={`${boardLink}/post/${content.boardId}`} state={{ herf: window.location.search }}>
                        <dl>
                          <dt>
                            <img src={content?.distFileName} alt="thumbnail" />
                            {/**국가별 뱃지 */}
                            {boardAuth?.isAll && <CountryBadge countryCodes={content?.countryCodes} />}
                          </dt>
                          <dd>
                            <h3>{content.title}</h3>
                            <span>{dayjs(content.createdDate).format("YYYY.MM.DD")}</span>
                          </dd>
                        </dl>
                      </Link>
                    </li>
                  ))}
                </S5.BoardCardList>
              ) : (
                <EmptyCard />
              )}
              {/**페이징 */}
              <CardTail line>
                <Paging totalCount={boards?.totalElements} currentPage={searchCondition.page} size={searchSize} handleChangePage={handleChangePage} />
              </CardTail>
            </>
          )}
        </S.CardInner>
      </S.CardCol>
    </S.CardRow>
  );
};

export default BoardList;
