import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetStudyStatus } from "../../apis/patientApi";
import { EmptyCard } from "../../elements/EmptyComponent/EmptyCard";
import { IK } from "../../utils/i18n/keyStore";
import { studyStatusComponents } from "../../utils/studyStatus";
import ModalLayout from "../Modal/ModalLayout";
import PrescriptionSkeleton from "./studySkeleton/PrescriptionSkeleton";
import * as S from "./style/Study.style";

const PrescriptionModal = ({ setOpenPrescription, openPrescription, patientId, studyId, studyInfo, studyAuth }) => {
  const { t } = useTranslation(["translation"]);

  /**처방전 내역 조회 */
  const { data, isLoading } = useGetStudyStatus(patientId, studyId);
  const studyList = data?.data;

  /**모달 닫기 */
  const handleClose = () => {
    setOpenPrescription(false);
  };

  return (
    <ModalLayout open={openPrescription} handleClose={handleClose} header={`${t(IK[studyInfo.packages])} (${dayjs(studyInfo?.createdDate).format("YYYY-MM-DD")})`}>
      {isLoading ? (
        <PrescriptionSkeleton />
      ) : (
        <>
          {
            /* 이력 있을 경우 */
            studyList.length > 0 ? (
              <div>
                <S.StudyHistoryList>
                  {studyList.map((item) => {
                    return (
                      <li key={item.statusId}>
                        {studyStatusComponents({ studyInfo: { ...item, ...{ writerShow: studyAuth?.userPermission?.writerShow && studyAuth?.isKorea, patientId } } }, item.detail)}
                      </li>
                    );
                  })}
                </S.StudyHistoryList>
              </div>
            ) : (
              <EmptyCard />
            )
          }
        </>
      )}
    </ModalLayout>
  );
};

export default PrescriptionModal;
