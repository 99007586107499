import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { IK } from "../utils/i18n/keyStore";

/** 환자 정보제공 동의서 업데이트 */
export const useInsertAgreementFile = () => {
  const { t } = useTranslation(["translation"]);
  const queryClient = useQueryClient();
  return useMutation(
    async ({ patientId, data }) => {
      await axios.post(`/api/patient/${patientId}/agreement`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("PatientInfo");
        queryClient.invalidateQueries("PatientList");
      },
      onError: (err) => {
        if (err?.response?.status !== 403) {
          Notiflix.Notify.failure(t(IK.again_message));
        }
      },
    }
  );
};

/** 환자 리스트 조회*/
export const useGetPatientList = (searchCondition) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["PatientList", searchCondition],
    async () => {
      const { data } = await axios.get("/api/admin/patient/list", {
        params: searchCondition,
      });
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**환자 상세 정보 조회*/
export const useGetPatientInfo = (patientId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["PatientInfo", patientId],
    async () => {
      const { data } = await axios.get(`/api/patient/${patientId}/info`);
      return data;
    },
    {
      enabled: !!patientId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**환자 동의서 정보 조회*/
export const useGetClinicInfo = (patientId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["ClinicInfo", patientId],
    async () => {
      const { data } = await axios.get(`/api/admin/patient/${patientId}/agreement`);
      return data;
    },
    {
      enabled: !!patientId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**환자 정보 수정용 정보 조회*/
export const useGetEditPatientInfo = (patientId, openModal) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["EditPatientInfo", patientId],
    async () => {
      const { data } = await axios.get(`/api/admin/patient/${patientId}/info`);
      return data;
    },
    {
      enabled: !!patientId && openModal,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**환자 정보 수정*/
export const useEditPatientInfo = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);

  return useMutation(
    async (submit) => {
      await axios.put(`/api/admin/patient/${submit.id}/info`, submit.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("EditPatientInfo");
        queryClient.invalidateQueries("PatientInfo");
        Notiflix.Report.success(t(IK.update_success), "", t(IK.confirm), () => {});
      },
      onError: () => {
        Notiflix.Report.failure(t(IK.update_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**환자 삭제 버튼 표출 여부 */
export const useGetPatientBtn = (patientId) => {
  return useQuery(
    ["PatientBtn", patientId],
    async () => {
      const { data } = await axios.get(`/api/patient/${patientId}/info/modify-button`);
      return data;
    },
    {
      enabled: !!patientId,
    }
  );
};

/**환자 삭제*/
export const useDeletePatient = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();

  return useMutation(
    async (patientId) => {
      await axios.delete(`/api/admin/patient/${patientId}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("PatientList");
        Notiflix.Report.success(t(IK.delete_success), "", t(IK.confirm), () => {
          navigate("/patientList");
        });
      },
      onError: () => {
        Notiflix.Report.failure(t(IK.delete_fail), t(IK.again_message), t(IK.confirm), () => {});
      },
    }
  );
};

/**환자의 처방전 리스트를 조회 */
export const useGetPatientStudyList = (patientId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["PatientStudyList", patientId],
    async () => {
      const { data } = await axios.get(`/api/patient/${patientId}/info/study-list`);
      return data;
    },
    {
      enabled: !!patientId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**환자 처방전 내역 상세 조회 */
export const useGetStudyStatus = (patientId, studyId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["StudyStatus", patientId, studyId],
    async () => {
      const { data } = await axios.get(`/api/admin/patient/${patientId}/info/${studyId}`);
      return data;
    },
    {
      enabled: !!studyId && !!patientId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**환자 엑셀 다운로드 */
export const getLoadPatientExcel = async (searchCondition, t, langInfo) => {
  Notiflix.Loading.standard("");

  try {
    const response = await axios.get(`/api/admin/patient/excel`, {
      params: {
        ...searchCondition,
        language: langInfo,
      },
      responseType: "arraybuffer",
    });

    const fileBlob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const fileURL = URL.createObjectURL(fileBlob);

    const anchor = document.createElement("a");
    anchor.href = fileURL;
    anchor.download = "patientList_serafin.xlsx";
    anchor.click();

    URL.revokeObjectURL(fileURL);
    anchor.remove();
  } catch (error) {
    Notiflix.Notify.failure(t(IK.again_message));
  } finally {
    Notiflix.Loading.remove(); // 성공 또는 실패 시 로딩 제거
  }
};

/**환자 처방전 세라뷰 웹/ 세라뷰 이동 */
export const getStudyLink = async (payload, t) => {
  try {
    const response = await axios.get(`/api/patient/${payload.patientId}/link`, {
      params: payload,
    });
    window.open(response.data.data, "_blank");
  } catch (error) {
    Notiflix.Notify.failure(t(IK.again_message));
  }
};

/**스터디 반려 사유 조회 */
export const useGetRejectReason = (patientId, studyStatusId, openRejetModal) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["RejectReason", patientId, studyStatusId],
    async () => {
      const { data } = await axios.get(`/api/patient/${patientId}/${studyStatusId}/comment`);
      return data;
    },
    {
      enabled: !!patientId && !!studyStatusId && openRejetModal,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**의사아이디로 관련 환자 조회 */
export const useGetDoctorPatientList = (doctorId, searchCondition) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["DoctorPatientList", doctorId, searchCondition],
    async () => {
      const { data } = await axios.get(`/api/admin/doctor/${doctorId}/patients`, {
        params: searchCondition,
      });
      return data;
    },
    {
      enabled: !!doctorId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**환자 선택 이관 */
export const useTransferPatient = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (submit) => {
      await axios.put(`/api/admin/patient/migration-each`, submit);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DoctorPatientList");
        Notiflix.Report.success("환자 선택 이관 완료", "선택된 환자 이관이 완료되었습니다.", "확인", () => {});
      },
      onError: () => {
        Notiflix.Report.failure("이관 실패", "다시 시도해주세요.", "확인", () => {});
      },
    }
  );
};

/**환자 전체 이관 */
export const useTransferAllPatient = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (submit) => {
      await axios.put(`/api/admin/patient/migration`, submit);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DoctorPatientList");
        Notiflix.Report.success("환자 전체 이관 완료", "전체 환자 이관이 완료되었습니다.", "확인", () => {});
      },
      onError: () => {
        Notiflix.Report.failure("이관 실패", "다시 시도해주세요.", "확인", () => {});
      },
    }
  );
};

/**환자의 세라퀵 파일 조회 */
export const useGetSeraquickFile = (patientId) => {
  const { t } = useTranslation(["translation"]);
  return useQuery(
    ["SeraquickFile", patientId],
    async () => {
      const { data } = await axios.get(`/api/admin/patient/${patientId}/simulation`);
      return data;
    },
    {
      enabled: !!patientId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};
