import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import Notiflix, { Confirm } from "notiflix";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useAddPopup, useDeletePopup, useGetPopup, useUpdatePopup } from "../apis/popupApi";
import { FormSkeleton } from "../components/clinicalGallery/CliniCalGalleryInfoSkeleton";
import CountryBtn from "../components/country/CountryBtn";
import SelectedCountryList from "../components/country/SelectedCountryList";
import { CardTail, CardTitle } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import * as S3 from "../elements/Button/style/ButtonLayout.style";
import { TimePicker } from "../elements/DatePicker";
import { FormControlsBox } from "../elements/Form/FormLayout";
import * as S2 from "../elements/Form/style/Form.style";
import { ErrorMessage, NotiMessage } from "../elements/Message/MessageText";
import * as S4 from "../elements/SelectionControls/style/SelectionControls.style";
import { usePermissionCheck } from "../hooks/usePermissionCheck";
import { IK } from "../utils/i18n/keyStore";

const PopUpPost = () => {
  const { t } = useTranslation(["translation"]);
  const { popupId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  /**권한 조회 */
  const popupAuth = usePermissionCheck("popupList");

  /**기존 데이터 받아오기
   * 수정시에만 조회
   */
  const { data: popInfo, isLoading } = useGetPopup(popupId);

  /**유효성 */
  const formSchema = yup.object().shape({
    introDisplay: yup.string().required(t(IK.pop_used_required)),
    countryCodes: yup.array().when("introDisplay", {
      is: (val) => val === "false",
      then: () => yup.array().required(t(IK.country_required)),
    }),
    type: yup.string().required(t(IK.pop_type_required)),
    name: yup.string().required(t(IK.pop_name_required)),
    display: yup.string().required(t(IK.pop_used_required)),
    order: yup.string().when("display", {
      is: (val) => val === "true",
      then: (val) =>
        yup
          .string()
          .matches(/^\d*$/, t(IK.only_number))
          .required(t(IK.pop_order_required))
          .test("not-zero", t(IK.zero_not_allowed), (value) => value !== "0"),
    }),
    link: yup.string().when(["type"], {
      is: (val) => val === "YOUTUBE",
      then: () => yup.string().required(t(IK.pop_link_required)),
    }),
    file: yup.mixed().when(["type"], {
      is: (val) => val === "IMAGE",
      then: () => yup.mixed().required(t(IK.pop_image_required)),
    }),
    startDate: yup.string().when("expDate", {
      is: (val) => !val,
      then: (val) => yup.string().required(t(IK.coupon_start_required)),
      otherwise: (val) => yup.string(),
    }),
    endDate: yup.string().when("expDate", {
      is: (val) => !val,
      then: (val) => yup.string().required(t(IK.coupon_end_required)),
      otherwise: (val) => yup.string(),
    }),
  });

  /**검색 조건 설정 */
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      type: "IMAGE",
    },
  });

  const boardWatch = watch();

  /**게시 시작 설정 */
  const handleStartDate = (date) => {
    if (date) {
      setValue("expDate", false);
      setValue("startDate", dayjs(date).format("YYYY-MM-DD HH:mm:ss"));
    } else {
      setValue("startDate", "");
    }
  };

  /**게시 종료 날짜 설정 */
  const handleEndDate = (date) => {
    if (date) {
      setValue("expDate", false);
      setValue("endDate", dayjs(date).format("YYYY-MM-DD HH:mm:ss"));
    } else {
      setValue("endDate", "");
    }
  };

  /**제한없음 설정 */
  const handleExpDate = () => {
    setValue("startDate", "");
    setValue("endDate", "");
  };

  const deleteMutation = useDeletePopup();
  /**팝업 삭제 */
  const onDelete = () => {
    Confirm.show(t(IK.warning_delete_1), t(IK.warning_delete_2), t(IK.confirm), t(IK.cancel), () => {
      deleteMutation.mutate(popupId);
    });
  };

  const postPopUp = useAddPopup();
  const editPopUp = useUpdatePopup();
  const handleRegister = (data) => {
    const { expDate, file, ...rest } = data;

    if (rest.startDate > rest.endDate) {
      //종료일이 시작일 보다 빠른 경우 경고
      return Notiflix.Report.failure(t(IK.event_date_invaild), t(IK.again_message), t(IK.confirm), () => {});
    }

    const formData = new FormData();
    Object.entries(rest).forEach(([key, value]) => {
      if (!!value) formData.append(key, value);
    });

    //파일이 파일타입이고 파일이 있을 경우
    if (!!file && file instanceof File) formData.append("file", file);

    if (popupId) {
      formData.append("id", popupId);
      editPopUp.mutate(formData);
    } else {
      postPopUp.mutate(formData);
    }
  };

  useEffect(() => {
    if (popInfo) {
      const { type, name, display, order, link, startDate, endDate, imageDistFileName, countryCodes, introDisplay } = popInfo;

      setValue("introDisplay", introDisplay.toString());
      setValue("type", type);
      setValue("name", name);
      setValue("display", display.toString());

      //display가 true일 경우 order값을 넣어줌
      if (display === "true") setValue("order", order);

      if (imageDistFileName) setValue("file", imageDistFileName);
      if (link) setValue("link", link);

      if (startDate && endDate) {
        setValue("startDate", startDate);
        setValue("endDate", endDate);
      } else {
        setValue("expDate", true);
      }

      if (countryCodes?.length > 0) setValue("countryCodes", countryCodes);
    }
  }, [popInfo, setValue]);

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardInner>
          <CardTitle title={t(IK.pop_up)} txt />
          {isLoading ? (
            <>
              <S2.FormControlsContainer>
                <FormSkeleton width={200} />
                <FormSkeleton width={400} />
                <FormSkeleton width={400} />
                <FormSkeleton width={200} />
                <FormSkeleton width={200} />
                <FormSkeleton width={200} />
              </S2.FormControlsContainer>
              <CardTail line>
                <S3.ButtonLtBox>
                  <Skeleton width={109} height={45} />
                  <Skeleton width={109} height={45} />
                  <Skeleton width={109} height={45} />
                </S3.ButtonLtBox>
              </CardTail>
            </>
          ) : (
            <form onSubmit={handleSubmit(handleRegister)}>
              <S2.FormControlsContainer>
                {/**인트로 노출 */}
                {popupAuth?.isAll && (
                  <FormControlsBox required title={t(IK.intro_use)}>
                    <S4.SelectionItemList>
                      <S4.SelectionItem>
                        <S4.SelectionItemLabel>
                          <S4.SelectionItemInput $radio type="radio" id="pop_intro_use" {...register("introDisplay")} value="true" />
                          <S4.SelectionItemSpan htmlFor="pop_intro_use">{t(IK.pop_used_use)}</S4.SelectionItemSpan>
                        </S4.SelectionItemLabel>
                      </S4.SelectionItem>
                      <S4.SelectionItem>
                        <S4.SelectionItemLabel>
                          <S4.SelectionItemInput $radio type="radio" id="pop_intro_use_not" {...register("introDisplay")} value="false" />
                          <S4.SelectionItemSpan htmlFor="pop_intro_use_not">{t(IK.pop_used_not_use)}</S4.SelectionItemSpan>
                        </S4.SelectionItemLabel>
                      </S4.SelectionItem>
                    </S4.SelectionItemList>
                    <ErrorMessage errors={errors} name="introDisplay" />
                    <NotiMessage text={t(IK.intro_use_desc)} />
                  </FormControlsBox>
                )}
                {/* 국가 선택 */}
                {popupAuth?.isAll && boardWatch.introDisplay === "false" && (
                  <>
                    <FormControlsBox required title={t(IK.country)}>
                      <CountryBtn setValue={setValue} clearErrors={clearErrors} watch={boardWatch?.countryCodes} />
                      <ErrorMessage errors={errors} name="countryCodes" />
                    </FormControlsBox>
                    {boardWatch?.countryCodes?.length > 0 && (
                      <FormControlsBox required title={`${t(IK.country)} ${t(IK.list)}`}>
                        <SelectedCountryList watch={boardWatch.countryCodes} setValue={setValue} t={t} />
                      </FormControlsBox>
                    )}
                  </>
                )}
                {/**팝업 타입 */}
                <FormControlsBox required title={t(IK.pop_type)}>
                  <S4.SelectionItemList>
                    <S4.SelectionItem>
                      <S4.SelectionItemLabel>
                        <S4.SelectionItemInput disabled={!popupAuth?.isAll} $radio type="radio" id="pop_image" {...register("type")} value="IMAGE" />
                        <S4.SelectionItemSpan htmlFor="pop_image">{t(IK.pop_type_image)}</S4.SelectionItemSpan>
                      </S4.SelectionItemLabel>
                    </S4.SelectionItem>
                    <S4.SelectionItem>
                      <S4.SelectionItemLabel>
                        <S4.SelectionItemInput disabled={!popupAuth?.isAll} $radio type="radio" id="pop_video" {...register("type")} value="YOUTUBE" />
                        <S4.SelectionItemSpan htmlFor="pop_video">{t(IK.pop_type_video)}</S4.SelectionItemSpan>
                      </S4.SelectionItemLabel>
                    </S4.SelectionItem>
                  </S4.SelectionItemList>
                  <ErrorMessage errors={errors} name="type" />
                </FormControlsBox>
                {/**팝업명 */}
                <FormControlsBox required title={t(IK.pop_name)}>
                  <S2.FormControls disabled={!popupAuth?.isAll} {...register("name")} />
                  <ErrorMessage errors={errors} name="name" />
                </FormControlsBox>
                {/**게시 기간 */}
                <FormControlsBox required title={t(IK.pop_date)}>
                  <S2.FormControlsFlex>
                    <div>
                      <TimePicker
                        handleDataChange={handleStartDate}
                        placeholderText={t(IK.create_date)}
                        disabled={boardWatch.expDate || !popupAuth?.isAll}
                        selected={boardWatch.startDate && new Date(boardWatch.startDate)}
                        formTimeControls
                      />
                      {!boardWatch.startDate && <ErrorMessage errors={errors} name="startDate" />}
                    </div>

                    <div>
                      <TimePicker
                        handleDataChange={handleEndDate}
                        placeholderText={t(IK.event_end_date)}
                        disabled={boardWatch.expDate || !popupAuth?.isAll}
                        selected={boardWatch.endDate && new Date(boardWatch.endDate)}
                        formTimeControls
                      />
                      {!boardWatch.endDate && <ErrorMessage errors={errors} name="endDate" />}
                    </div>
                    <S4.SelectionItem>
                      <S4.SelectionItemLabel>
                        <S4.SelectionItemInput disabled={!popupAuth?.isAll} $checkbox type="checkbox" id="expDate" {...register("expDate")} onClick={handleExpDate} />
                        <S4.SelectionItemSpan>{t(IK.no_limit)}</S4.SelectionItemSpan>
                      </S4.SelectionItemLabel>
                    </S4.SelectionItem>
                  </S2.FormControlsFlex>
                </FormControlsBox>
                {/**사용 여부 */}
                <FormControlsBox required title={t(IK.pop_used)}>
                  <S4.SelectionItemList>
                    <S4.SelectionItem>
                      <S4.SelectionItemLabel>
                        <S4.SelectionItemInput disabled={!popupAuth?.isAll} $radio type="radio" id="pop_use" {...register("display")} value="true" />
                        <S4.SelectionItemSpan htmlFor="pop_use">{t(IK.pop_used_use)}</S4.SelectionItemSpan>
                      </S4.SelectionItemLabel>
                    </S4.SelectionItem>
                    <S4.SelectionItem>
                      <S4.SelectionItemLabel>
                        <S4.SelectionItemInput disabled={!popupAuth?.isAll} $radio type="radio" id="pop_not_use" {...register("display")} value="false" />
                        <S4.SelectionItemSpan htmlFor="pop_not_use">{t(IK.pop_used_not_use)}</S4.SelectionItemSpan>
                      </S4.SelectionItemLabel>
                    </S4.SelectionItem>
                  </S4.SelectionItemList>
                  <ErrorMessage errors={errors} name="display" />
                </FormControlsBox>

                {/**팝업 순서 */}
                {boardWatch?.display === "true" && (
                  <FormControlsBox title={t(IK.pop_order)} required>
                    <S2.FormControls disabled={!popupAuth?.isAll} $small {...register("order")} defaultValue={popInfo?.order || ""} placeholder="ex) 1" />
                    <ErrorMessage errors={errors} name="order" />
                  </FormControlsBox>
                )}

                {/**이미지 전용 */}
                {boardWatch.type === "IMAGE" && (
                  <>
                    <FormControlsBox required title={t(IK.pop_image)}>
                      <S2.FormControlsFlex>
                        <S2.FormControls disabled value={boardWatch.file?.name || popInfo?.imageDistFileName || ""} />
                        <S2.FormControls disabled={!popupAuth?.isAll} type="file" id="file" accept="image/*" onChange={(event) => setValue("file", event.target.files[0])} hidden />
                        <S2.FormControlsBtn as="label" htmlFor="file">
                          {t(IK.file_btn)}
                        </S2.FormControlsBtn>
                      </S2.FormControlsFlex>
                      {!boardWatch.popImage && <ErrorMessage errors={errors} name="file" />}
                      <NotiMessage text={`${t(IK.recommended_size)} 560 x 560`} />
                    </FormControlsBox>
                    <FormControlsBox title={t(IK.pop_link)}>
                      <S2.FormControls disabled={!popupAuth?.isAll} {...register("link")} />
                    </FormControlsBox>
                  </>
                )}

                {boardWatch.type === "YOUTUBE" && (
                  <FormControlsBox required title={t(IK.pop_lyoutube_ink)}>
                    <S2.FormControls disabled={!popupAuth?.isAll} {...register("link")} />
                    <NotiMessage text={t(IK.pop_link_desc)} />
                    <ErrorMessage errors={errors} name="link" />
                  </FormControlsBox>
                )}
              </S2.FormControlsContainer>
              <CardTail line>
                <S3.ButtonLtBox>
                  {popupId ? (
                    <>
                      <S3.StyledButton as="button" type="button" onClick={() => navigate(`/support/popup${location.state?.herf || `?page=1`}`)}>
                        {t(IK.list)}
                      </S3.StyledButton>
                      {popupAuth?.isAll && (
                        <>
                          <S3.StyledButton as="button" $secondary type="button" onClick={onDelete}>
                            {t(IK.delete)}
                          </S3.StyledButton>
                          <S3.StyledButton as="button" $primary type="submit">
                            {t(IK.modify)}
                          </S3.StyledButton>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <S3.StyledButton type="button" to={"/support/popup"}>
                        {t(IK.cancel)}
                      </S3.StyledButton>
                      <S3.StyledButton as="button" type="submit" $primary>
                        {t(IK.registration)}
                      </S3.StyledButton>
                    </>
                  )}
                </S3.ButtonLtBox>
              </CardTail>
            </form>
          )}
        </S.CardInner>
      </S.CardCol>
    </S.CardRow>
  );
};

export default PopUpPost;
